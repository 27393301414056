<template>
  <div>
    <div class="embed-responsive" style="min-height:610px">
      <iframe class="embed-responsive-item" :src="uriView" allowfullscreen></iframe>
    </div>
    <div class="m-1" style="font-size: 0.8em">
      <strong> {{ $t('chartByTradingView') }} </strong> <a :href="uriView" target="_blank"> TradingView </a>
    </div>
  </div>
</template>
<script>

export default {
  name: 'TradingView',
  props: {
    symbol: {
      type: String,
      default: '',
      description: 'symbol'
    },
    exchange: {
      type: String,
      default: '',
      description: 'exchange'
    }
  },
  data() {
    return {}
  },

  computed: {
    uriView: function () {
      return `https://s.tradingview.com/widgetembed/?frameElementId=tradingview_a6e15&symbol=${this.exchange}%3A${this.symbol.replace('/', '').replace('-', '')}&interval=1D&details=1&hide_side_toolbar=0&symboledit=1&saveimage=1&toolbarbg=f1f3f6&studies=%5B%5D&theme=dark&style=1&timezone=Etc%2FUTC&withdateranges=1&showpopupbutton=1&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&showpopupbutton=1&locale=en&utm_source=tradio-vuejs.vercel.app&utm_medium=widget&utm_campaign=chart&utm_term=${this.exchange}%3A${this.symbol.replace('/', '').replace('-', '')}`
    }
  }
}
</script>
<style>
</style>
