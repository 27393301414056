<template>
  <div class="container">
    <div class="row">
      <sidebar-share> </sidebar-share>
      <div class="col-md-10 mr-auto ml-auto mt-4">

        <back-to-login />

        <card class="card-body">
          <div class="col-md-12">
              <tabs type="primary" vertical class="row">
                <tab-pane :label='$t("resetPassword.requestRecoveryCode")'>
                  <card class="card-body">
                    <template slot="header">
                      <!-- <img class="card-img" src="img/card-primary.png" alt="Card image"/> -->
                      <h3 class="card-title pl-2">  {{ $t("resetPassword.requestRecoveryCode") }} </h3>
                      <h4 class="card-title pl-2"> {{ $t("resetPassword.requestRecoveryCodeSubTitle") }} </h4>
                    </template>
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
                      <form @submit.prevent="handleSubmit(submit)">
                        <ValidationProvider
                          :name='$t("register.email")'
                          rules="required|email"
                          v-slot="{ passed, failed, errors }"
                          >
                          <base-input
                            required
                            v-model="form.email"
                            :placeholder='$t("register.email")'
                            addon-left-icon="tim-icons icon-email-85"
                            type="email"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          </base-input>
                        </ValidationProvider>

                        <base-button native-type="submit" slot="footer" type="primary" :disabled="invalid || requestOn" round block size="lg">
                          {{ $t("resetPassword.request") }}
                        </base-button>

                      </form>
                    </ValidationObserver>
                  </card>
                </tab-pane>

                <tab-pane :label='$t("resetPassword.haveRecoveryCode")'>
                  <card class="card-body">
                    <template slot="header">
                      <!-- <img class="card-img" src="img/card-primary.png" alt="Card image"/> -->
                      <h3 class="card-title pl-2"> {{ $t("resetPassword.haveRecoveryCode") }} </h3>
                      <h4 class="card-title pl-2"> {{ $t("resetPassword.haveRecoveryCodeSubTitle") }} </h4>
                    </template>
                    <ValidationObserver ref="formAlter" v-slot="{ handleSubmit, invalid }">
                      <form @submit.prevent="handleSubmit(alterSubmit)">
                        <ValidationProvider
                          :name='$t("resetPassword.recoveryCode")'
                          rules="required"
                          v-slot="{ passed, failed, errors }"
                          >
                          <base-input
                            required
                            v-model="form.recoveryCode"
                            :placeholder='$t("resetPassword.recoveryCode")'
                            addon-left-icon="tim-icons icon-lock-circle"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          </base-input>
                        </ValidationProvider>

                        <ValidationProvider
                          :name='$t("resetPassword.password")'
                          vid="password"
                          rules="required|min:5"
                          v-slot="{ passed, failed, errors }"
                        >
                        <base-input
                          addon-left-icon="tim-icons icon-lock-circle"
                          type="password"
                          autocomplete="off"
                          required
                          v-model="form.password"
                          :placeholder='$t("resetPassword.password")'
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                        </ValidationProvider>

                        <ValidationProvider
                          :name='$t("resetPassword.confirmPassword")'
                          rules="required|min:5|confirmed:password"
                          v-slot="{ passed, failed, errors }"
                        >
                        <base-input
                          autocomplete="off"
                          type="password"
                          addon-left-icon="tim-icons icon-lock-circle"
                          required
                          v-model="form.confirmPassword"
                          :placeholder='$t("resetPassword.confirmPassword")'
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        </base-input>
                        </ValidationProvider>

                        <base-button native-type="submit" slot="footer" type="primary" :disabled="invalid || requestOn" round block size="lg">
                         {{ $t("resetPassword.changePassword") }}
                        </base-button>
                      </form>
                    </ValidationObserver>
                  </card>
                </tab-pane>
              </tabs>
          </div>
        </card>

        <networks />
      </div>
    </div>
  </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem, BaseAlert } from '@/components';
import SidebarShare from '@/pages/Layout/SidebarSharePlugin';
import { BaseCheckbox } from '@/components';
import { newCode, changePassword } from '@/services';
import { extend } from "vee-validate";
import { required, email, min, confirmed } from "vee-validate/dist/rules";
import Networks from '@/pages/Components/Networks'
import BackToLogin from '@/pages/Components/BackToLogin'

extend("email", email);
extend("required", required);
extend("min", min);
extend("confirmed", confirmed);

export default {
  components: {
    BaseCheckbox,
    SidebarShare,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
    BaseAlert,
    Networks,
    BackToLogin
  },
  data() {
    return {
      requestOn: false,
      form: {
        email: "",
        recoveryCode: "",
        password: "",
        confirmPassword: ""
      }
    };
  },
  methods: {
    async submit() {
      this.requestOn = true
      try {
        const { email } = this.form
        await newCode(email);
        this.form.email = ""

        this.$nextTick(() => this.$refs.form.reset());
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 7000 });

      } catch ({ response }) {
        const { data } = response;
        const message = data.code || data.email[0]
        this.$notify({ type: 'danger', message, verticalAlign: 'top', horizontalAlign: 'center', timeout: 7000 });
      }
      this.requestOn = false
    },

    async alterSubmit() {
      this.requestOn = true
      try {
        const { password, recoveryCode, confirmPassword } = this.form
        await changePassword({ password, code: recoveryCode, cpassword: confirmPassword });

        this.form.recoveryCode = "",
        this.form.password = "",
        this.form.confirmPassword = ""

        this.$nextTick(() => this.$refs.formAlter.reset());
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 7000 });
        setTimeout(() => this.$router.push({ name: 'Login' }), 7000);

      } catch ({ response }) {
        const { data } = response;
        const message = data.code || data.email[0]
        this.$notify({ type: 'danger', message, verticalAlign: 'top', horizontalAlign: 'center', timeout: 7000 });
      }
      this.requestOn = false
    }
  },
  created() {

  }
};
</script>
<style>

</style>
