<template>
  <div class="row">
    <balance :ws="ws" />

    <div class="col-md-4">
      <card>
        <template slot="header">
          <h4 class="card-title"> {{ $t('online') }}: <span class="font-weight-bold"> {{ online }} </span>  </h4>
        </template>
      </card>
    </div>

    <div class="col-md-8">
      <breadcrumb :transparent="true">
        <breadcrumb-item active>
            <router-link :ws="ws" :to="{ name: 'UserIndex' }" class="mr-2">  {{ $t("users") }}  </router-link>
            <router-link :ws="ws" :to="{ name: 'Accounting' }" class="mr-2">  {{ $t("accounting") }}  </router-link>
        </breadcrumb-item>
      </breadcrumb>
    </div>

    <div class="col-md-12">
        <router-view :ws="ws"></router-view>
    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BaseNav, Breadcrumb, BreadcrumbItem } from '@/components'

export default {
  props: ['ws'],
  components: {
    BaseNav,
    Breadcrumb,
    BreadcrumbItem
  },
  data() {
    return {
      menu: ""
    };
  },
  computed: {
    ...mapGetters({
      online: "user/online"
    }),


  },
  methods: {

  }
};
</script>
<style scoped>
.router-link-exact-active {
  font-weight: 600;
}
</style>
