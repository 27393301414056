<template>
  <div class="row">
    <balance :ws="ws" />
    <brokerage-situation :ws="ws" />

    <div class="col-md-12">
      <div class="row">

        <div class="col-md-12">
          <card>

            <template slot="header">
              <h4 class="card-title">
                {{ $t('orders.errors') }} ({{ ordersWithErrors.length }})
                <span v-if="search"> <badge type="warning"> {{ $t("filter") }}: {{ search }} </badge> <base-button size="sm" type="danger" @click="() => search='' "> X </base-button> </span>
              </h4>
            </template>

            <div class="card-body">
              <div v-if="blocked === true">
                <base-alert type="danger">
                  {{ $t('orders.deactivatedAccount') }}
                </base-alert>
              </div>
              <div v-else>
                <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
                <div v-if="ordersWithErrors.length && requestOn === false">
                  <el-table
                    class="table-striped"
                    height="61vh"
                    ref="multipleOrders"
                    :default-sort="{ prop: 'status.value', order: 'descending' }"
                    :data="ordersWithErrors.filter(data => !search || data.symbol.toLowerCase().includes(search.toLowerCase()))">

                    <el-table-column type="expand">
                      <template slot-scope="scope">
                        <div class="row" v-if="scope.row.operations.length > 0">
                          <div class="col-md-12 mb-2"> <strong class="card-title"> {{ $t('openOperations.title') }} </strong> </div>
                          <div class="col-md-12 mb-2" :key="operation.id" v-for="operation of scope.row.operations">
                            <div class="col-md-12">
                             <span class="min-span"> {{ $t('orders.amount') }} : {{ operation.amount }} {{ scope.row.group }} </span>


                              <base-button class="ml-4" size="sm" simple type="danger" v-if="scope.row.inDifference < 0 && onlyView === false" @click="deleteOperation(scope.row, operation)" :disabled="scope.row.request">
                                  {{ $t('delete') }}
                              </base-button>

                            </div>
                          </div>
                        </div>


                        <div class="row text-center" v-if="scope.row.operations.length === 0">
                          {{ $t("none") }}
                        </div>

                      </template>
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      min-width="150"
                      prop="symbol"
                      :label="$t('orders.par')">

                      <template slot="header">
                        <base-input v-model="search"
                          type="text"
                          :placeholder="$t('openOperations.par')"/>
                      </template>

                    </el-table-column>

                    <el-table-column
                      color="primary"
                      min-width="160"
                      prop="wallet"
                      sortable
                      :label="$t('orders.wallet')">
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      min-width="160"
                      prop="inWallet"
                      sortable
                      :label="$t('orders.inOrder')">
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      min-width="160"
                      prop="inDifference"
                      sortable
                      :label="$t('orders.inDifference')">
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      min-width="170"
                      prop="messageError"
                      sortable
                      :label="$t('orders.message')">
                    </el-table-column>

                    <el-table-column
                      color="primary"
                      min-width="210"
                      prop="action"
                      sortable
                      :label="$t('orders.clearErrors')">
                      <template slot-scope="scope">

                        <base-button class="ml-2 mb-2 mt-1" size="sm" simple
                          type="warning"
                          v-if="onlyView === false"
                          :disabled="scope.row.request"
                          @click="clearErrors(scope.row)">
                          {{ $t('orders.clearErrors') }}
                        </base-button>

                      </template>
                    </el-table-column>


                  </el-table>
                </div>
                <div class="text-center" v-if="ordersWithErrors.length === 0 && requestOn === false"> {{ $t('orders.none') }} </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui'
import { mapGetters } from 'vuex'
import { BaseAlert, BaseCheckbox, Badge } from '@/components'

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseAlert,
    BaseCheckbox,
    Badge
  },
  data() {
    return {
      requestOn: false,
      requestOrdersAll: false,
      apiIsActive: false,
      switchOn: false,
      max_buy: 200,
      search: "",
      symbols: []
    };
  },
  computed: {
    ...mapGetters({
      orders: 'user/orders',
      blocked: "user/blocked",
      onlyView: "user/onlyView"
    }),
    ordersWithErrors: function() {
      return this.orders.filter(o => o.countError >= 3)
    }
  },
  methods: {
    clearErrors(row) {
      const orderId = row.id
      const action = !row.buy_lock

      if (this.ws.connected) {
        if (confirm(this.$t("doYouReally"))) {
          row.request = true
          setTimeout(() => row.request = false, 7000);
          this.ws.emit('orderCleanErrors', { orderId });
        }
      }
    },
    deleteOperation(row, operation) {
      if (this.ws.connected) {
        if (confirm(this.$t("doYouReally"))) {
          row.request = true
          setTimeout(() => row.request = false, 5000);
          this.ws.emit('deleteOperation', { orderId: row.id, operationId: operation.id });
        }
      }
    }
  },
  created() {
  },
};
</script>
<style></style>
