<template>
  <div class="row">

    <company-user-balance />

    <div class="col-md-6" v-if="user && user.id">
      <card>
        <template slot="header">
          <h4 class="card-title">
            <router-link :to="{ name: 'CompanyOrdersUser' }">
              <base-button class="ml-2" size="sm" simple type="success">
                {{ $t("return") }}
              </base-button>
            </router-link>

            {{ $t('orders.newOrder') }}
          </h4>
        </template>
        <div class="card-body">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
            <form @submit.prevent="handleSubmit(submitNew)">

              <base-input
                v-model="search"
                @input="changeSearch"
                autocomplete="off"
                :placeholder='$t("parityPreFilter")'>
              </base-input>

              <ValidationProvider
                :name='$t("orders.par")'
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  v-model="form.par"
                  autocomplete="off"
                  :label='$t("orders.par")'
                  addon-left-icon="tim-icons icon-basket-simple"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  <select class="form-control" v-model="form.par">
                    <option value=""> {{ $t("select") }} </option>
                    <option :value="s.id" :key="s.id" v-for="s of allowSymbolSelect"> {{ s.symbolWithExchange }} </option>
                  </select>
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.value")'
                  :rules="{ required: true, regex: /^\d+\.?\d{0,2}/, min_value: 20, max_value: max_buy }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  disa
                  v-model="form.value"
                  autocomplete="off"
                  :label='$t("orders.value")'
                  addon-left-icon="tim-icons icon-money-coins"
                  :error="errors[0]"
                  @input="onlyNumberDecimal('value')"
                  @change="onlyNumberDecimal('value')"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.operationsAllowed")'
                  :rules="{ required: true, regex: /^\d+\.?\d{0,0}/, min_value:1 , max_value: 100 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="form.operationsAllowed"
                  autocomplete="off"
                  :label='$t("orders.operationsAllowed")'
                  addon-left-icon="tim-icons icon-notes"
                  :error="errors[0]"
                  @input="onlyNumber('operationsAllowed')"
                  @change="onlyNumber('operationsAllowed')"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.expectedProfit")'
                  :rules="{ required: true, regex: /^\d+\.?\d{0,2}/, min_value:1, max_value: 4.07 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="form.profit"
                  autocomplete="off"
                  :label='$t("orders.expectedProfit")'
                  addon-left-icon="tim-icons icon-chart-pie-36"
                  :error="errors[0]"
                  @input="onlyNumberDecimal('profit')"
                  @change="onlyNumberDecimal('profit')"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.maximumPurchasePrice")'
                  rules=""
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.maximumPurchasePrice"
                  autocomplete="off"
                  :label='$t("orders.maximumPurchasePrice")'
                  addon-left-icon="tim-icons icon-lock-circle"
                  :error="errors[0]"
                  @input="onlyNumberDecimal('maximumPurchasePrice')"
                  @change="onlyNumberDecimal('maximumPurchasePrice')"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.minimumPurchasePrice")'
                  rules=""
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.minimumPurchasePrice"
                  autocomplete="off"
                  :label='$t("orders.minimumPurchasePrice")'
                  addon-left-icon="tim-icons icon-lock-circle"
                  :error="errors[0]"
                  @input="onlyNumberDecimal('minimumPurchasePrice')"
                  @change="onlyNumberDecimal('minimumPurchasePrice')"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  v-if="form.par"
                  :name='$t("orders.operationProfile")'
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  autocomplete="off"
                  :label='$t("orders.operationProfile")'
                  addon-left-icon="tim-icons icon-badge"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  <select class="form-control" v-model="form.operationProfile" @change="verifyPerfil()">
                    <option :value="4">Ultra agressivo</option>
                    <option :value="2">Agressivo</option>
                    <option :value="3">Moderado</option>
                    <option :value="1">Conservador</option>
                    <option :value="5">Ultra Conservador</option>
                  </select>
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.activeAnalysis")'
                  :label='$t("orders.activeAnalysis")'
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  autocomplete="off"
                  required
                  :label='$t("orders.activeAnalysis")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    <base-radio inline name="yes" class="mb-3" v-model="form.activeAnalysis">
                          {{ $t('yes') }}
                    </base-radio>

                    <base-radio inline name="no" class="mb-3" v-model="form.activeAnalysis">
                        {{ $t('no') }}
                    </base-radio>
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.savePartOfTheProfitInCurrency")'
                  :label='$t("orders.savePartOfTheProfitInCurrency")'
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  autocomplete="off"
                  required
                  :label='$t("orders.savePartOfTheProfitInCurrency")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    <base-radio inline name="yes" class="mb-3" v-model="form.savePartOfTheProfitInCurrency">
                          {{ $t('yes') }}
                    </base-radio>

                    <base-radio inline name="no" class="mb-3" v-model="form.savePartOfTheProfitInCurrency">
                        {{ $t('no') }}
                    </base-radio>
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.securityLock")'
                  :label='$t("orders.securityLock")'
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  autocomplete="off"
                  required
                  :label='$t("orders.securityLock")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    <base-radio inline name="yes" class="mb-3" v-model="form.securityLock">
                          {{ $t('yes') }}
                    </base-radio>

                    <base-radio inline name="no" class="mb-3" v-model="form.securityLock">
                        {{ $t('no') }}
                    </base-radio>
                </base-input>
              </ValidationProvider>


              <errors :rows="getErrors()" />

              <base-button native-type="submit" type="primary" class="mb-3" v-if="onlyView === false" :disabled="invalid || requestOn" size="lg" block>
                {{ $t('orders.createOrder') }}
              </base-button>
            </form>
          </ValidationObserver>
        </div>
      </card>
    </div>

    <div class="col-md-6" v-if="user && user.id">
      <card>
        <template slot="header">
          <h4 class="card-title"> {{ $t("orders.orderOverview") }} </h4>
        </template>
        <div class="card-body">
          <div v-if="hasOrderOverview()">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-4">
                  <strong class="text-success" v-html="$t('orderInfoLabel')"></strong>
                </div>
              </div>
              <div class="col-md-5">
                  <div class="text-center mb-2">
                    {{ $t("orders.view") }}
                  </div>
                  <div class="text-center mb-4">
                    <strong>
                      {{ $t('orders.operationsAllowed') }} x {{ $t('orders.value') }} = <span class="text-success"> ${{ (form.value * form.operationsAllowed).toFixed(2) }} </span>
                    </strong>
                  </div>

                  <div class="text-center mp-4">
                    {{ $t('dashboard.riskRate', { current: 'USD' }) }}
                  </div>
                  <div class="text-center mb-4">
                    <strong :class="riskRateClass(riskRate())"> {{ riskRate() }}% </strong>
                  </div>

                  <div class="text-center mp-4">
                    {{ $t('dashboard.riskRateExt', { current: 'USD' }) }}
                  </div>
                  <div class="text-center">
                    <strong :class="riskRateClass(riskRate(form.value * form.operationsAllowed))"> {{ riskRate(form.value * form.operationsAllowed) }}% </strong>
                  </div>
              </div>
              <div class="col-md-7">
                  <div class="text-center mb-4"> {{ $t("orders.viewLog") }} </div>
                  <div>
                    <table class="table table-striped">
                        <thead>
                        <tr>
                          <th scope="col"> {{ $t("orders.noOfOrders") }} </th>
                          <th scope="col"> {{ $t("orders.assetQuotation") }} </th>
                          <th scope="col"> {{ $t("orders.betweenPurchases") }} </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr :key="index" v-for="(quant, index) in parseInt(form.operationsAllowed) > 100 ? 100 : parseInt(form.operationsAllowed)">
                          <td> {{ quant }} </td><td> ${{ forecast(quant, 1)  }} </td><td>   {{ forecast(quant, 2) }}% </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import { required, email, min_value, max_value, numeric, regex } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { BaseAlert, BaseRadio } from '@/components'
import { listSymbols, userInfo, newOrderCompany } from '@/services/index'
import Errors from '@/components/Errors/Index'
import balance from '../balance.vue'

extend("email", email);
extend("numeric", numeric);
extend("regex", regex);
extend("min_value", min_value);
extend("max_value", max_value);
extend("required", required);

function randomNumber(min, max) {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

export default {
  props: ['ws'],
  components: {
    BaseAlert,
    BaseRadio,
    Errors,
    'company-user-balance': balance
  },
  data() {
    return {
      requestOn: false,
      search: "",
      max_buy: 200,
      symbols: [],
      form: {
        buy_back: 21,
        value: "",
        profit: 2,
        profit_save: 30,
        par: "",
        activeAnalysis: "yes",
        operationProfile: 3,
        operationsAllowed: 7,
        maximumPurchasePrice: 0,
        minimumPurchasePrice: 0,
        savePartOfTheProfitInCurrency: "yes",
        securityLock: "yes"
      }
    };
  },
  computed: {
    ...mapGetters({
      user: 'company/user',
      riskRate: "company/riskRate",
      onlyView: "user/onlyView"
    }),

    allowSymbol: function () {
      return this.symbols.filter(s => s.currency.toUpperCase() !== "BRL").filter(s => !this.user.orders.map(o => o.symbol_id).includes(s.id))
    },

    allowSymbolSelect: function () {
      return this.allowSymbol.filter(data => !this.search || data.symbolWithExchange.toLowerCase().includes(this.search.toLowerCase()))
    },

    exchange: function () {
      if (!this.form.par) return ""

      const symbol = this.symbols.find(s => s.id === this.form.par)
      return symbol.exchange || ""
    }

  },
  methods: {
    changeSearch() {
      this.form.par = ""
    },

    riskRateClass(risk) {
      if (risk <= 30) return 'text-success';
      if (risk <= 50) return 'text-warning';
      return 'text-danger';
    },

    getPar(id) {
      return this.symbols.find(s => s.id === id) || {}
    },

    diffPercentage(value, percentage) {
      return (value - (value * (percentage / 100)));
    },

    forecast(qant, type = 1) {
      let newValue = this.getPar(this.form.par).buyNow
      const baseValue = newValue
      let percentage = 0
      let buyBack = this.form.buy_back

      if (qant === 1) return type === 1 ? newValue : percentage

      for (let i = 1; i < qant; i++) {
        const oldValue = newValue
        newValue = this.diffPercentage(newValue, buyBack)
        percentage = ((newValue/baseValue) * 100) - 100;
      }
      return (type === 1) ? parseFloat(newValue.toFixed(8)) : parseFloat(percentage.toFixed(2))
    },

    hasOrderOverview() {
      return this.form.par > 0 && this.form.operationsAllowed && this.form.value >= 3 && this.getPar(this.form.par).buyNow
    },

    onlyNumberDecimal(field) {
      this.form[field] = (this.form[field].match(/^\d+\.?\d{0,8}/) || [''])[0];
    },

    onlyNumber(field) {
      this.form[field] = (this.form[field].match(/^\d+\.?\d{0,0}/) || [''])[0];
    },

    verifyPerfil() {
      this.form.buy_back = this.buyBack(this.form.operationProfile)
    },

    buyBack(perfil) {
      switch (parseInt(perfil)) {
        case 5: // ultra Conservadora
        return randomNumber(31, 33)
        case 1: // Conservadora
          return randomNumber(18, 21)
        case 3: // Moderada
          return randomNumber(10.91, 11.9)
        case 2: // Agressivo
          return randomNumber(9.2, 10.90)
        case 4:
          return randomNumber(8.87, 9.01)
        default:
          return randomNumber(18, 21)
      }
    },

    async listSymbols() {
      this.requestOn = true
      try {
        const res = await listSymbols()
        this.symbols = res.data.map(s => ({ id: s.id, symbol: s.symbol, exchange: s.exchange, symbolWithExchange: `${s.exchange.toUpperCase()}-${s.symbol.toUpperCase()}`, buyNow: parseFloat(s.priceNow), currency: s.currency, group: s.group, min_value: s.min_value }))
      } catch (e) {
        console.error(e)
      }
      this.requestOn = false
    },

    async submitNew() {

      const request = {
        buy_lock: false,
        symbol_id: this.form.par,
        value: this.form.value,
        max: parseInt(this.form.operationsAllowed),
        profit: parseFloat(this.form.profit),
        stop: 0.49,
        profile: parseInt(this.form.operationProfile),
        value_min: parseFloat(this.form.minimumPurchasePrice),
        value_max: parseFloat(this.form.maximumPurchasePrice),
        buy_back: parseFloat(this.form.buy_back),
        profit_save: this.form.savePartOfTheProfitInCurrency === 'yes' ? this.form.profit_save : 0,
        lockRed: this.form.securityLock === 'yes' ? true : false,
        activeAnalysis: this.form.activeAnalysis === 'yes' ? true : false,
      }

      if (confirm(this.$t("doYouReally"))) {
        this.requestOn = true

        try {
          await newOrderCompany(this.user.id, request)
          this.form.par = ""
          this.$nextTick(() => this.$refs.form.reset());
          this.$notify({ type: 'success', message: this.$t("orders.requestSuccessful") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 15000 });
        } catch ({ response }) {
          const { data } = response;
          const errros = Object.values(data).map(i => i[0])
          this.setErrors(errros)
        }

        this.requestOn = false
      }
    }
  },
  async created() {
    this.companyVerifyUser()

    await this.listSymbols()
    const resUser = await userInfo()

    this.max_buy = resUser.data.max_buy;
  },
};
</script>
<style></style>
