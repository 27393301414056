<template>
  <div class="row">
    <balance :ws="ws" />

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4">
          <card>
             <template slot="header">
              <h4 class="card-title">
                {{ $t('orders.newOrder') }}

                <router-link :to="{ name: 'Orders' }">
                  <base-button class="ml-2" size="sm" simple type="success">
                    {{ $t("return") }}
                  </base-button>
                </router-link>

              </h4>
             </template>
            <div class="card-body">
              <div v-if="blocked === false">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
                  <form @submit.prevent="handleSubmit(submitNewOrder)">
                    <ValidationProvider
                        :name='$t("orders.par")'
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        required
                        v-model="form.par"
                        autocomplete="off"
                        :label='$t("orders.par")'
                        addon-left-icon="tim-icons icon-basket-simple"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">

                        <v-select class="form-control" v-model="form.par" :options="allowSymbolSelect" label="symbolWithExchange"></v-select>

                        <!-- <select class="form-control" v-model="form.par">
                          <option value=""> {{ $t("select") }} </option>
                          <option :value="s.id" :key="s.id" v-for="s of allowSymbolSelect"> {{ s.symbolWithExchange }} {{ s.level ? `*(${s.level})` : '' }}</option>
                        </select> -->
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.value")'
                        :rules="{ required: true, regex: /^\d+\.?\d{0,2}/, min_value: minValue || 15, max_value: max_buy }"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        required
                        v-model="form.value"
                        autocomplete="off"
                        :label='$t("orders.value")'
                        addon-left-icon="tim-icons icon-money-coins"
                        :error="errors[0]"
                        @input="onlyNumberDecimal('value')"
                        @change="onlyNumberDecimal('value')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.operationsAllowed")'
                        :rules="{ required: true, regex: /^\d+\.?\d{0,0}/, min_value:1 , max_value: 100 }"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        required
                        v-model="form.operationsAllowed"
                        autocomplete="off"
                        :label='$t("orders.operationsAllowed")'
                        addon-left-icon="tim-icons icon-notes"
                        :error="errors[0]"
                        @input="onlyNumber('operationsAllowed')"
                        @change="onlyNumber('operationsAllowed')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.expectedProfit")'
                        :rules="{ required: true, regex: /^\d+\.?\d{0,2}/, min_value: showAdmin ? 0.7 : 1, max_value: 4.07 }"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        required
                        v-model="form.profit"
                        autocomplete="off"
                        :label='$t("orders.expectedProfit")'
                        addon-left-icon="tim-icons icon-chart-pie-36"
                        :error="errors[0]"
                        @input="onlyNumberDecimal('profit')"
                        @change="onlyNumberDecimal('profit')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>


                    <ValidationProvider
                        :name='$t("orders.percentageSavedInCoin")'
                        :rules="{ required: true, regex: /^\d+\.?\d{0,2}/, min_value:0, max_value: showAdmin ? 200 : 100 }"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        required
                        v-model="form.profit_save"
                        autocomplete="off"
                        :label='$t("orders.percentageSavedInCoin")'
                        addon-left-icon="tim-icons icon-chart-pie-36"
                        :error="errors[0]"
                        @input="onlyNumberDecimal('profit_save')"
                        @change="onlyNumberDecimal('profit_save')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <base-alert type="warning" v-if="hasRealProfit()">
                       {{ $t("orders.expectedProfit") }} + {{ $t("orders.percentageSavedInCoin") }} = <strong> {{ realProfit() }} </strong>
                    </base-alert>

                    <ValidationProvider
                        :name='$t("orders.maximumPurchasePrice")'
                        rules=""
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        v-model="form.maximumPurchasePrice"
                        autocomplete="off"
                        :label='$t("orders.maximumPurchasePrice")'
                        addon-left-icon="tim-icons icon-lock-circle"
                        :error="errors[0]"
                        @input="onlyNumberDecimal('maximumPurchasePrice')"
                        @change="onlyNumberDecimal('maximumPurchasePrice')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.minimumPurchasePrice")'
                        rules=""
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        v-model="form.minimumPurchasePrice"
                        autocomplete="off"
                        :label='$t("orders.minimumPurchasePrice")'
                        addon-left-icon="tim-icons icon-lock-circle"
                        :error="errors[0]"
                        @input="onlyNumberDecimal('minimumPurchasePrice')"
                        @change="onlyNumberDecimal('minimumPurchasePrice')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.operationProfile")'
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >

                      <p>
                        <label>
                          {{ $t("orders.operationProfile") }}
                          <span class="" data-toggle="tooltip" data-placement="top" :title="$t('note1')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                            </svg>
                          </span>
                        </label>
                      </p>
                      <base-input
                        required
                        autocomplete="off"
                        class="mb-4"
                        addon-left-icon="tim-icons icon-badge"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">

                        <select class="form-control" v-model="form.operationProfile" @change="verifyPerfil()">
                          <option :value="4">Ultra agressivo</option>
                          <option :value="2">Agressivo</option>
                          <option :value="3">Moderado</option>
                          <option :value="1">Conservador</option>
                          <option :value="5">Ultra Conservador</option>
                        </select>
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.activeAnalysis")'
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        autocomplete="off"
                        required
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          <p class="sub-marge">
                            <label>
                              {{ $t("orders.activeAnalysis") }}
                              <span class="" data-toggle="tooltip" data-placement="top" :title="$t('note2')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                </svg>
                              </span>
                            </label>
                          </p>
                          <base-radio inline name="yes" class="mb-2" v-model="form.activeAnalysis">
                               {{ $t('yes') }}
                          </base-radio>

                          <base-radio inline name="no" class="mb-2" v-model="form.activeAnalysis">
                              {{ $t('no') }}
                          </base-radio>
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.securityLock")'
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        autocomplete="off"
                        required
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          <p class="sub-marge">
                            <label>
                              {{ $t("orders.securityLock") }}
                              <span class="" data-toggle="tooltip" data-placement="top" :title="$t('note3')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                </svg>
                              </span>
                            </label>
                          </p>

                          <base-radio inline name="yes" class="mb-2" v-model="form.securityLock">
                               {{ $t('yes') }}
                          </base-radio>

                          <base-radio inline name="no" class="mb-2" v-model="form.securityLock">
                              {{ $t('no') }}
                          </base-radio>
                      </base-input>
                    </ValidationProvider>


                    <errors :rows="getErrors()" />

                    <base-button native-type="submit" type="primary" class="mb-3" v-if="onlyView === false" :disabled="invalid || requestOn" size="lg" block>
                      {{ $t('orders.createOrder') }}
                    </base-button>
                  </form>
                </ValidationObserver>
              </div>
              <div v-if="blocked === true && requestOn === false">
                <base-alert type="danger">
                  {{ $t('orders.deactivatedAccount') }}
                </base-alert>
              </div>

            </div>
          </card>
        </div>

        <div class="col-md-8">
          <card v-if="symbolSelected">
            <div class="card-body">
              <trading-view
                :symbol="symbolSelected.symbol"
                :exchange="symbolSelected.exchange"
              />
            </div>
          </card>

          <card>
            <div class="card-body">
              <h4  class="card-title"> {{ $t("orders.orderOverview") }} </h4>

              <div v-if="blocked === true">
                <base-alert type="danger">
                  {{ $t('orders.deactivatedAccount') }}
                </base-alert>
              </div>

              <div v-if="hasOrderOverview()">
                <div class="row">
                  <div class="col-md-6">
                    <table class="table">
                      <thead>
                        <tr>
                          <th colspan="2" class="text-center"> {{ $t("orders.view") }} </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="2" class="text-center">
                            <strong class="text-success"> ${{ (form.value * form.operationsAllowed).toFixed(2) }} </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="text-center"> {{ $t('dashboard.riskRateIn') }} </th>
                          <th class="text-center"> {{ $t("dashboard.riskRateExtIn") }} </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">
                            <strong :class="riskRateClass(riskRateRender())"> {{ riskRateRender() }}% </strong>
                          </td>
                          <td class="text-center">
                            <strong :class="riskRateClass(riskRateExRender(form.value * form.operationsAllowed))"> {{ riskRateExRender(form.value * form.operationsAllowed) }}% </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-6">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                          <th colspan="3" class="text-center"> {{ $t("orders.viewLog") }} </th>
                        </tr>
                        <tr>
                          <th scope="col"> {{ $t("orders.noOfOrders") }} </th>
                          <th scope="col"> {{ $t("orders.assetQuotation") }} </th>
                          <th scope="col"> {{ $t("orders.betweenPurchases") }} </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr :key="index" v-for="(quant, index) in parseInt(form.operationsAllowed) > 100 ? 100 : parseInt(form.operationsAllowed)">
                          <td> {{ quant }} </td><td> ${{ forecast(quant, 1)  }} </td><td>   {{ forecast(quant, 2) }}% </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import { mapGetters } from 'vuex'
import { listSymbols, userInfo, newOrder } from "@/services"
import { BaseAlert, BaseRadio } from 'src/components'
import { extend } from "vee-validate";
import { required, email, min_value, max_value, numeric, regex } from "vee-validate/dist/rules";
import Errors from '@/components/Errors/Index'
import TradingView from "@/components/TradingView"

extend("email", email);
extend("numeric", numeric);
extend("regex", regex);
extend("min_value", min_value);
extend("max_value", max_value);
extend("required", required);

function randomNumber(min, max) {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

export default {
  props: ['ws'],
  components: {
    BaseAlert,
    BaseRadio,
    Errors,
    TradingView
  },
  data() {
    return {
      requestOn: false,
      activeFtx: false,
      search: "",
      max_buy: 200,
      symbols: [],
      form: {
        buy_back: 21,
        value: "",
        profit: 2,
        profit_save: 30,
        par: "",
        activeAnalysis: "no",
        operationProfile: 3,
        operationsAllowed: 7,
        maximumPurchasePrice: 0,
        minimumPurchasePrice: 0,
        savePartOfTheProfitInCurrency: "yes",
        securityLock: "yes"
      }
    };
  },
  computed: {
    ...mapGetters({
      orders: 'user/orders',
      exchange: 'user/exchange',
      blocked: "user/blocked",
      riskRate: "user/riskRate",
      riskRateBrl: "user/riskRateBrl",
      riskRateExt: "user/riskRateExt",
      riskRateExtBrl: "user/riskRateExtBrl",
      onlyView: "user/onlyView",
      showAdmin: 'user/showAdmin',
    }),

    allowSymbol: function() {
      return this.symbols.filter(s => s.exchange === this.exchange && !this.orders.map(o => o.symbol_id).includes(s.id))
    },

    symbolSelected: function() {

      if (this.form.par && this.form.par.id) {
        const symbol = this.allowSymbol.find(a => a.id === this.form.par.id)
        return (!symbol) ? null : symbol
      }

      return null
    },

    allowSymbolSelect: function() {
      return this.allowSymbol.filter(data => !this.search || data.symbolWithExchange.toLowerCase().includes(this.search.toLowerCase()))
    },

    minValue: function() {
      const item = this.symbols.filter(s => s.exchange === this.exchange)

      if (item.length > 0) {
        return item[0].min_value
      }

      return 20
    },
  },
  methods: {

    changeSearch() {
      this.form.par = ""
    },

    hasRealProfit() {
      return ((parseFloat(this.form.profit) || 0) > 0 && parseFloat(this.form.profit) <= 4.07 && (parseFloat((this.form.profit_save) || 0)) > 0 && (parseFloat(this.form.profit_save) || 0) <= (this.showAdmin ? 200 : 100))
    },

    realProfit() {
      let text = this.$t('orders.realMinimumProfit')

      if ((parseFloat(this.form.profit) || 0) > 0 && ((parseFloat(this.form.profit_save) || 0) <= 0 || (parseFloat(this.form.profit_save) || 0) > (this.showAdmin ? 200 : 100)))
        text = text + ' ' + parseFloat((this.form.profit))

      if ((parseFloat(this.form.profit) || 0) > 0 && (parseFloat((this.form.profit_save) || 0)) > 0) {
        const value = (parseFloat(this.form.profit) + ((parseFloat((this.form.profit)) * parseFloat(this.form.profit_save)) / 100))
        text = text + ' ' + value.toFixed(2);
      }

      return text + '%';

    },

    diffPercentage(value, percentage) {
      return (value - (value * (percentage / 100)));
    },

    currentVerify() {
      return this.getPar(this.form.par.id || null).currency === 'brl' ? "BRL" : "USD"
    },

    riskRateRender() {
      return this.currentVerify() === "BRL" ? this.riskRateBrl : this.riskRate
    },

    riskRateExRender(value) {
      return this.currentVerify() === "BRL" ? this.riskRateExtBrl(value) : this.riskRateExt(value)
    },

    forecast(quant, type = 1) {
      let newValue = this.getPar(this.form.par.id).buyNow
      const baseValue = newValue
      let percentage = 0
      let buyBack = this.form.buy_back

      if (quant === 1) return type === 1 ? newValue : percentage

      for (let i = 1; i < quant; i++) {
        const oldValue = newValue
        newValue = this.diffPercentage(newValue, buyBack)
        percentage = ((newValue/baseValue) * 100) - 100;
      }
      return (type === 1) ? parseFloat(newValue.toFixed(8)) : parseFloat(percentage.toFixed(2))
    },


    riskRateClass(risk) {
      if (risk <= 30) return 'text-success';
      if (risk <= 50) return 'text-warning';
      return 'text-danger';
    },

    hasOrderOverview() {
      return this.blocked === false && this.form.par && this.form.par.id > 0 && this.form.operationsAllowed && this.form.value >= 3 && this.getPar(this.form.par.id).buyNow
    },

    onlyNumberDecimal(field) {
      this.form[field] = (this.form[field].match(/^\d+\.?\d{0,8}/) || [''])[0];
    },

    onlyNumber(field) {
      this.form[field] = (this.form[field].match(/^\d+\.?\d{0,0}/) || [''])[0];
    },

    async listSymbols() {
      this.requestOn = true
      try {
        const res = await listSymbols()
        this.symbols = res.data.map(s => ({
          id: s.id,
          symbol: s.symbol,
          exchange: s.exchange,
          symbolWithExchange: `${s.exchange.toUpperCase()}-${s.symbol.toUpperCase()}`,
          buyNow: parseFloat(s.priceNow),
          currency: s.currency,
          group: s.group,
          min_value: s.min_value,
          level: s.level || '',
        }))
      } catch (e) {
        console.error(e)
      }
      this.requestOn = false
    },

    getPar(id) {
      return this.symbols.find(s => s.id === id) || {}
    },

    buyBack(perfil) {
      switch (parseInt(perfil)) {
        case 5: // ultra Conservadora
        return randomNumber(25, 33)
        case 1: // Conservadora
          return randomNumber(18, 21)
        case 3: // Moderada
          return randomNumber(9, 11)
        case 2: // Agressivo
          return randomNumber(6, 8)
        case 4: // ultra Agressivo
          return randomNumber(4.3, 5)
        default:
          return randomNumber(9, 11) // default
      }
    },

    verifyPerfil() {
      this.form.buy_back = this.buyBack(this.form.operationProfile)
    },

    async submitNewOrder() {
      const request = {
        buy_lock: false,
        symbol_id: this.form.par.id,
        value: this.form.value,
        max: parseInt(this.form.operationsAllowed),
        profit: parseFloat(this.form.profit),
        stop: 0.25,
        perfil: parseInt(this.form.operationProfile),
        value_min: parseFloat(this.form.minimumPurchasePrice),
        value_max: parseFloat(this.form.maximumPurchasePrice),
        buy_back: parseFloat(this.form.buy_back),
        profit_save: this.form.profit_save,
        lockRed: this.form.securityLock === 'yes' ? true : false,
        activeAnalysis: this.form.activeAnalysis === 'yes' ? true : false,
      }

      if (confirm(this.$t("doYouReally"))) {
        this.requestOn = true
        try {
          await newOrder(request)
          this.form.par = ""
          this.$nextTick(() => this.$refs.form.reset());
          this.$notify({ type: 'success', message: this.$t("orders.requestSuccessful") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 15000 });

          setTimeout(() => {
            this.requestOn = false
            if (this.allowSymbol.length === 0) {
              this.$router.push({ name: 'Orders' })
            }
          }, 11000)
        } catch ({ response }) {
          const { data } = response;
          const errors = Object.values(data).map(i => i[0])
          this.setErrors(errors)
          this.requestOn = false
        }

      }

    }
  },
  async created() {
    await this.listSymbols()
    const resUser = await userInfo()

    this.apiIsActive = resUser.data.apiIsActive
    this.apiIsActiveFtx = resUser.data.apiIsActiveFtx
    this.apiIsActiveHuobi = resUser.data.apiIsActiveHuobi
    this.apiIsActiveKucoin = resUser.data.apiIsActiveKucoin
    this.max_buy = resUser.data.max_buy;

    if (this.exchange === 'ftx' && this.apiIsActiveFtx === false) {
      this.$notify({ type: 'warning', message: this.$t("notFtx") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 10000 });
      this.$router.push({ name: 'Orders' })
      return
    }

    if (this.exchange === 'binance' && this.apiIsActive === false) {
      this.$notify({ type: 'warning', message: this.$t("notBinance") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 10000 });
      this.$router.push({ name: 'Orders' })
      return
    }

    if (this.exchange === 'huobi' && this.apiIsActiveHuobi === false) {
      this.$notify({ type: 'warning', message: this.$t("notHuobi") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 10000 });
      this.$router.push({ name: 'Orders' })
      return
    }

    if (this.exchange === 'kucoin' && this.apiIsActiveKucoin === false) {
      this.$notify({ type: 'warning', message: this.$t("notKucoin") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 10000 });
      this.$router.push({ name: 'Orders' })
      return
    }

    if (this.exchange === 'okex' && this.apiIsActiveOkex === false) {
      this.$notify({ type: 'warning', message: this.$t("notOkex") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 10000 });
      this.$router.push({ name: 'Orders' })
      return
    }

    this.verifyPerfil()

    setTimeout(() => (this.allowSymbol.length === 0) ? this.$router.push({ name: 'Orders' }) : "", 3000)
  },
};
</script>
<style>
  .sub-marge {
    margin-bottom: -8px;
  }

  .vs__dropdown-toggle {
    border: 0!important;
    margin-top: -5px!important;
  }

  .vs__selected  {
    color: #EEE!important;
  }

  .vs__search {
    color: #EEE!important;
  }

  .white-content .vs__search {
    color: #333!important;
  }

  .white-content .vs__selected  {
    color: #333!important;
  }



  /* .white-content > .vs__selected .vs__clear .vs__open-indicator {
    color: #777!important;
  } */
</style>
