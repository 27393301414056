<template>
  <div class="row">
    <balance :ws="ws" />

    <div class="col-md-12">
      <card :title="$t('reports.soon')">

      </card>
    </div>

  </div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';

export default {
  props: ['ws'],
  components: {
    Card

  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
  mounted() {

  },
  beforeDestroy() {

  }
};
</script>
<style></style>
