<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">
            <strong> {{ $t('company.welcome') }} </strong>
          </h4>
        </template>
      </card>
    </div>

  </div>
</template>
<script>
export default {
  props: ['ws'],
  components: {

  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  }
};
</script>
<style></style>
