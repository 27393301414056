<template>
  <div>
    <p class="mb-2">
      <router-link class="link footer-link  d-text d-s" to="login">
        <i class="tim-icons icon-minimal-left"></i>
        {{ $t("resetPassword.backToLogin") }}
      </router-link>
    </p>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
    };
  }
};
</script>
<style></style>
