<template>
  <div class="row">

    <company-user-balance />

    <div class="col-md-12"  v-if="user && user.id">
      <card>
        <template slot="header">
          <h4 class="card-title">
            <router-link :to="{ name: 'CompanyUsers' }">
              <base-button class="ml-2" size="sm" simple type="success">
                {{ $t("return") }}
              </base-button>
            </router-link>

            {{ `${$t('closeOperations.title')} (${operations.total})` }}
          </h4>
        </template>

        <h4 v-if="altcoinsSelect.length">
          {{ $t('closeOperations.filters') }}
        </h4>
        <div class="row" v-if="altcoinsSelect.length">
          <div class="col-md-4">
            <div class="form-group mb-4">
              <label for="altcoin"> {{ $t('closeOperations.altcoin') }} </label>
              <select class="form-control" id="altcoin" v-model="filter.altcoin" @change="changeAltcoin">
                <option value=""> {{ $t('closeOperations.select') }} </option>
                <option :value="coin.value" v-for="coin in altcoinsSelect" :key="coin.value"> {{ coin.value }} </option>
              </select>
            </div>
          </div>
          <div class="col-md-8">
              <label for="altcoin"> {{ $t('closeOperations.soldIn') }} </label>
            <div class="form-group mb-4">
              <el-date-picker
                v-model="filter.dates"
                type="daterange"
                lang="pt-br"
                @change="changeDate"
                :picker-options="pickerOptions"
                :start-placeholder="$t('closeOperations.startDate')"
                :end-placeholder="$t('closeOperations.endDate')">
              </el-date-picker>
            </div>
          </div>
        </div>

        <div v-if="operations.data.length && requestOn === false">
          <el-table class="table-striped" :data="operations.data">

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="150"
              prop="par"
              :label="$t('closeOperations.par')">
            </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="130"
              prop="exchange"
              :label="$t('closeOperations.exchange')">
            </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="150"
              prop="purchasePrice"
              :label="$t('closeOperations.purchasePrice')">
            </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="150"
              prop="salePrice"
              :label="$t('closeOperations.salePrice')">
            </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="140"
              prop="paid"
              :label="$t('closeOperations.paid')">
            </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="140"
              prop="sold"
              :label="$t('closeOperations.sold')">
            </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="180"
              prop="profit"
              :label="$t('closeOperations.profit')">
              <template slot-scope="scope">
                <strong :class="viewProfit(scope.row)"> {{ viewPercent(scope.row.paid, scope.row.sold) }}% = {{ scope.row.profit }} </strong>
              </template>
            </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="170"
              prop="altcoinSaved"
              :label="$t('closeOperations.altcoinSaved')">
            </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="155"
              prop="boughtIn"
              :label="$t('closeOperations.boughtIn')">
            </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="155"
              prop="soldIn"
              :label="$t('closeOperations.soldIn')">
            </el-table-column>

          </el-table>
          <base-pagination :total="operations.total" v-model="operations.current_page" @input="changePage"></base-pagination>
        </div>
        <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
        <div class="text-center" v-if="operations.data.length === 0 && requestOn === false"> {{ $t('closeOperations.none') }} </div>
      </card>
    </div>

  </div>
</template>
<script>
import moment from 'moment';
import { Table, TableColumn, DatePicker } from 'element-ui'
import { listCloseOperationsCompany } from "@/services"
import { setCloseOperations } from "@/adapters"
import { BasePagination } from '@/components'
import { mapGetters } from 'vuex'
import balance from '../../../balance.vue'

export default {
  props: ['ws'],
  components: {
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    'company-user-balance': balance
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      requestOn: false,
      operations: {
        total: 0,
        current_page: 1,
        data: []
      },
      filter: {
        dates: null,
        altcoin: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      user: 'company/user',
    }),

    orders: function() {
      return this.user.orders
    },

    altcoinsSelect: function() {
      let rows =  this.orders.map(o => {
        let value = o.symbol

        const usds = ["USDT", "BUSD", "USDC", "DAI", "PAX", "TUSD", 'BRL', 'HUSD', 'USD']
        for (const usd of usds) value = value.replace(usd,'');

        value = value.replace('/', '');
        value = value.replace('-', '');
        return value
      })

      rows = Array.from(new Set(rows))
      return rows.map(s => ({ value: s })).sort((a, b) => a.value !== b.value ? a.value < b.value ? -1 : 1 : 0);

    }
  },
  methods: {
    changePage(page) {
      this.listCloseOperationsCompany({ page })
    },
    changeDate(dates) {
      if (Array.isArray(dates)) {
        const start = moment(dates[0])
        const end = moment(dates[1])

        if(end.diff(start, 'days') > 30) {
          this.filter.dates = null
          this.$notify({ type: 'danger', verticalAlign: 'top', horizontalAlign: 'center', message: this.$t("closeOperations.selectRange") });
          return;
        }
      }

      this.listCloseOperationsCompany({ page: 1 })
    },
    changeAltcoin() {
     this.listCloseOperationsCompany({ page: 1 })
    },
    viewProfit(item) {
      if (item.profit > 0) return { 'text-success' : true };
      if (item.profit <= 0 && item.sell <= item.buy) return { 'text-danger' : true }
      return { 'text-warning': true }
    },
    viewPercent(a, b) {
      return (((b - a) / a) * 100).toFixed(2)
    },
    async listCloseOperationsCompany(params = {}) {

      if(Array.isArray(this.filter.dates)) {
        const startDate = `${moment(this.filter.dates[0]).format("YYYY-MM-DD")} 00:00:00`
        const endDate = `${moment(this.filter.dates[1]).format("YYYY-MM-DD")} 23:59:59`

        params["startDate"] = startDate
        params["endDate"] = endDate
      }

      if(this.filter.altcoin) {
        params["group"] = this.filter.altcoin
      }

      this.requestOn = true
      try {
        const format = this.$root.$i18n.locale === 'br' ? 'DD/MM/YYYY HH:mm' : 'MM-DD-YYYY HH:mm'
        const response  = await listCloseOperationsCompany(this.user.id, params)
        const { data, total, from, to, current_page } = response.data

        this.operations.total = total
        this.operations.current_page = current_page
        this.operations.data = data.map(o => setCloseOperations(o, format))

      } catch (e) {
         this.operations.total = 0
        this.operations.current_page = 1
        this.operations.data = []
      }
      this.requestOn = false
    }
  },
  async created() {
    this.companyVerifyUser()
    await this.listCloseOperationsCompany()
  }
};
</script>
<style>


    @media (max-width: 600px)
    {
      .el-date-range-picker__content {
        width: 100%!important;
      }

      .el-date-range-picker .el-picker-panel__body{
        min-width:230px
      }
    }

  .el-picker-panel {
    max-width: 80%
  }

</style>
