<template>
  <div class="row">
    <div class="col-12">
      <p style="font-size: 0.9em;">
        <a href="https://t.me/neionetwork" target="_blank" rel="noopener" class="link footer-link d-text d-s">
          {{ $t("telegram") }}
        </a>
      </p>

      <p style="font-size: 0.9em;">
        <a href="https://www.instagram.com/neio_network/" target="_blank" rel="noopener" class="link footer-link d-text d-s">
          {{ $t("instagram") }}
        </a>
      </p>
    </div>
    <div class="col-12">
      <card class="card-body">
        <template slot="header">
          <h1 class="card-title" style="font-size: 1.2em"> {{ $t("Create your exchange account") }} </h1>
        </template>
          <p style="font-size: 0.9em;">
            <a href="https://accounts.binance.com/register?ref=482479004" target="black" class="link footer-link d-text d-s">
              {{ $t("login.createBinanceAccount", { exchange: "Binance" }) }}
            </a>
          </p>
          <p style="font-size: 0.9em;">
            <a href="https://www.kucoin.com/r/rf/QBS4KLEF" target="black" class="link footer-link d-text d-s">
              {{ $t("login.createBinanceAccount", { exchange: "Kucoin" }) }}
            </a>
          </p>
          <p style="font-size: 0.9em;">
            <a href="https://www.okex.com/pt-br/join/2837578" target="black" class="link footer-link d-text d-s">
              {{ $t("login.createBinanceAccount", { exchange: "Okex" }) }}
            </a>
          </p>
      </card>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
    };
  }
};
</script>
<style></style>
