<template>
  <div class="container">
    <h1 style="display:none"> {{ $t("meetNeiO") }} </h1>

    <sidebar-share> </sidebar-share>
    <div class="col-lg-5 col-md-7 ml-auto mr-auto mt-4">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-body">
            <template slot="header">
              <!-- <img src="img/card-primary.png" alt="" /> -->
              <h1 class="card-title" style="font-size: 1.6em"> {{ $t("login.login") }} </h1>
            </template>

            <div>
              <ValidationProvider
                :name='$t("login.email")'
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
              <base-input
                v-model="email"
                autocomplete="off"
                :placeholder='$t("login.email")'
                addon-left-icon="tim-icons icon-email-85"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
             </ValidationProvider>

             <ValidationProvider
               :name='$t("login.password")'
               rules="required|min:5"
               v-slot="{ passed, failed, errors }"
             >
             <base-input
               required
               v-model="password"
               autocomplete="off"
               :placeholder='$t("login.password")'
               addon-left-icon="tim-icons icon-lock-circle"
               type="password"
               :error="errors[0]"
               :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
             </base-input>
            </ValidationProvider>

              <base-input
                required
                v-model="twoFactors"
                maxlength="6"
                autocomplete="off"
                :placeholder='$t("login.twoFactors")'
                addon-left-icon="tim-icons icon-lock-circle">
              </base-input>

              <errors :rows="getErrors()" />
            </div>

            <div slot="footer">
              <base-button native-type="submit" type="primary" class="mb-3" :disabled="invalid || requestOn" size="lg" block>
                {{ $t("enter") }}
                <span class="spinner-grow" v-loading="requestOn"></span>
              </base-button>
              <div class="pull-left">
                <h6 style="font-size: 0.9em;">
                  <router-link class="link footer-link d-text d-s" :to="{ name: 'Register'}">
                    {{ $t("login.createNeioAccount") }}
                  </router-link>
                </h6>
                <h6 style="font-size: 0.9em;">
                  <router-link class="link footer-link d-text d-s" :to="{ name: 'ResetPassword'}">
                    {{ $t("login.forgotPassword") }}
                  </router-link>
                </h6>
              </div>
            </div>
          </card>
        </form>
      </ValidationObserver>

      <networks />

    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Errors from '@/components/Errors/Index'
import SidebarShare from '@/pages/Layout/SidebarSharePlugin';
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { runLogin } from '@/services'
import Networks from '@/pages/Components/Networks'

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  components: {
    SidebarShare,
    Errors,
    Networks
  },
  data() {
    return {
      email: "",
      password: "",
      twoFactors: "",
      requestOn: false
    };
  },
  created() {
    if (this.$route.query.token == 'okey')
      setTimeout(() => { this.$notify({ type: 'success', message: this.$t("Account activated successfully") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 15000 }) }, 1200)

  },
  methods: {
    ...mapActions({
      setToken: 'user/setToken'
    }),

    submit() {
      this.requestOn = true
      this.resetErrors()
      let query = {}

      if(this.email) query["login"] = this.email
      if(this.password) query["password"] = this.password
      if(this.twoFactors) query["twoFactors"] = this.twoFactors

      runLogin(query)
        .then(({ data }) => {
          if (data.errors) data.errors = data.errors.map(a => ({ message: this.$t(`errorsMessage.${this.removeDot(a.message)}`) }))

          if(this.verifyErrors(data) === false) {
            this.login = ""
            this.password = ""
            this.twoFactors = ""
            this.$nextTick(() => this.$refs.form.reset());

            const { generateToken } = data.data

            this.setToken(generateToken)
            this.$router.push({ name: 'Dashboard' });
          }
        })
        .catch(({ response }) => {
          console.log(response)
          const { data } = response;
          this.verifyErrors(data)
        })
        .then(() => this.requestOn = false)
    }
  },
  computed: {

  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
