<template>
  <div class="container">
    <sidebar-share> </sidebar-share>
    <div class="col-md-10 ml-auto mr-auto mt-4">
      <card>
        <template slot="header">
          <h4 class="card-title">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td> {{ $t('companyUser.name') }}: </td>
                  <td> {{ name }}  </td>
                </tr>
                <tr>
                  <td> {{ $t('openOperations.date') }}: </td>
                  <td> {{ created_at }} </td>
                </tr>
              </tbody>
            </table>
            {{ $t('accountStatus.title', { current }) }} <br />
          </h4>
        </template>

        <div class="row">

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                  {{ $t('accountStatus.valueInPurchases') }}  <br />
                  <strong> ${{ valueInPurchases }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                  {{ $t('accountStatus.deficit') }}  <br />
                  <strong :class="situationClass(-deficit)"> ${{ -deficit }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                  {{ $t('accountStatus.profit') }}  <br />
                  <strong :class="situationClass(sumProfit)"> ${{ sumProfit }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center" v-if="requestOn === false">
                  {{ $t('accountStatus.altcoinsSaved') }}  <br />
                  <strong :class="situationClass(sumSymbolsOnHold)"> ${{ sumSymbolsOnHold }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center" v-if="requestOn === false">
                  {{ $t('accountStatus.altcoinsSold') }}  <br />
                  <strong :class="situationClass(sumOfConfirmedAmountOnHold)"> ${{ sumOfConfirmedAmountOnHold }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center"  v-if="requestOn === false">
                  {{ $t('accountStatus.netProfit') }}  <br />
                  <strong :class="situationClass(profit)"> ${{ profit }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                  {{ $t('accountStatus.riskRate') }}  <br />
                  <strong :class="riskRateClass(riskRate)"> {{ riskRate }}% </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                  {{ $t('dashboard.capitalAtRisk') }}  <br />
                  <strong :class="riskRateClass(capitalAtRisk)"> ${{ capitalAtRisk }} </strong>
                </h4>
              </template>
            </card>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import Errors from '@/components/Errors/Index'
import SidebarShare from '@/pages/Layout/SidebarSharePlugin';
import { getAccountStatusCompany } from '@/services/index'

export default {
  components: {
    SidebarShare,
    Errors
  },
  data() {
    return {
      current: "USD",
      valueInPurchases: 0,
      sumOfConfirmedAmountOnHold: 0,
      deficit: 0,
      sumProfit: 0,
      profit: 0,
      riskRate: 0,
      capitalAtRisk: 0,
      sumSymbolsOnHold: 0,
      requestOn: false,
      name: ''
    };
  },
  beforeCreate() {
    document.title = 'VIEW';
  },

  async created() {

    const { auth } = this.$route.query
    const { data } = await getAccountStatusCompany({ auth })
    this.name = data.name
    this.valueInPurchases = data.valueInPurchases
    this.profit = data.netIncome
    this.sumProfit = data.profit
    this.riskRate = data.riskRate
    this.deficit = data.deficit
    this.capitalAtRisk = data.capitalAtRisk
    this.sumSymbolsOnHold = data.altcoinSaved
    this.sumOfConfirmedAmountOnHold = data.altcoinSold
    this.created_at = data.created_at
  },
  methods: {

    riskRateClass(risk) {
      if (risk <= 30) return 'text-success';
      if (risk <= 50) return 'text-warning';
      return 'text-danger';
    },

    showSituation(v1, v2) {
      return parseFloat((((v1 / v2) * 100) - 100).toFixed(2));
    },

    situationClass(risk) {
      if (risk < 0) return 'text-warning';
      return 'text-success'
    },

  },
  computed: {

  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
