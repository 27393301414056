<template>
  <div class="row">
    <balance :ws="ws" />

    <div class="col-md-12">
      <card :title='$t("route.Company")'>

        <div class="col-md-12" v-if="show && !requestOn">
            <a href="https://business-fe6f5.web.app/login" target="_blank"> <h4 style="text-decoration: underline;"> NeiO Business </h4> </a>
        </div>

        <div class="col-md-12" v-if="requestOn">
            <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-loading="true"> </div>
        </div>

        <div class="col-md-12" v-if="!show && !requestOn">
          <base-alert type="warning">
            {{ $t("company.notActive") }}
          </base-alert>
        </div>
      </card>
    </div>

  </div>
</template>
<script>
import { BaseNav, Breadcrumb, BreadcrumbItem } from '@/components'
import { BaseAlert } from 'src/components';
import { mapGetters } from 'vuex'
import balance from './Pages/users/balance.vue'

export default {
  props: ['ws'],
  components: {
    BaseNav,
    Breadcrumb,
    BreadcrumbItem,
    BaseAlert,
    'company-user-balance': balance
  },
  data() {
    return {
      requestOn: true,
    };
  },
  computed: {
    ...mapGetters({
      showCompany: 'user/showCompany',
      user: 'company/user',
      showAdmin: 'user/showAdmin',
    }),

    show: function () {
      return this.showCompany
    }
  },
  methods: {

  },
  created() {
    setTimeout(() => this.requestOn = false, 5000);
  }
};
</script>
<style scoped>
.router-link-exact-active {
  font-weight: 600;
}
</style>
