<template>
  <div class="row">

    <company-user-balance />

    <div class="col-md-6"  v-if="user && user.id">
      <card>
        <template slot="header">
          <h4 class="card-title">
            <router-link :to="{ name: 'CompanyOrdersUser' }">
              <base-button class="ml-2" size="sm" simple type="success">
                {{ $t("return") }}
              </base-button>
            </router-link>

            {{ $t('orders.massEditing') }}
          </h4>
        </template>
        <div class="card-body">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
            <form @submit.prevent="handleSubmit(submitEdit)">
              <ValidationProvider
                  :name='$t("orders.value")'
                  :rules="{ regex: /^\d+\.?\d{0,2}/, min_value: 20, max_value: max_buy }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.value"
                  autocomplete="off"
                  :label='$t("orders.value")'
                  addon-left-icon="tim-icons icon-money-coins"
                  :error="errors[0]"
                  @input="onlyNumberDecimal('value')"
                  @change="onlyNumberDecimal('value')"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.operationsAllowed")'
                  :rules="{ regex: /^\d+\.?\d{0,0}/, min_value:1 , max_value: 100 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.operationsAllowed"
                  autocomplete="off"
                  :label='$t("orders.operationsAllowed")'
                  addon-left-icon="tim-icons icon-notes"
                  :error="errors[0]"
                  @input="onlyNumber('operationsAllowed')"
                  @change="onlyNumber('operationsAllowed')"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.expectedProfit")'
                  :rules="{ regex: /^\d+\.?\d{0,2}/, min_value:1, max_value: 4.07 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.profit"
                  autocomplete="off"
                  :label='$t("orders.expectedProfit")'
                  addon-left-icon="tim-icons icon-chart-pie-36"
                  :error="errors[0]"
                  @input="onlyNumberDecimal('profit')"
                  @change="onlyNumberDecimal('profit')"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.maximumPurchasePrice")'
                  rules=""
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.maximumPurchasePrice"
                  autocomplete="off"
                  :label='$t("orders.maximumPurchasePrice")'
                  addon-left-icon="tim-icons icon-lock-circle"
                  :error="errors[0]"
                  @input="onlyNumberDecimal('maximumPurchasePrice')"
                  @change="onlyNumberDecimal('maximumPurchasePrice')"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.minimumPurchasePrice")'
                  rules=""
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.minimumPurchasePrice"
                  autocomplete="off"
                  :label='$t("orders.minimumPurchasePrice")'
                  addon-left-icon="tim-icons icon-lock-circle"
                  :error="errors[0]"
                  @input="onlyNumberDecimal('minimumPurchasePrice')"
                  @change="onlyNumberDecimal('minimumPurchasePrice')"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.operationProfile")'
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  autocomplete="off"
                  :label='$t("orders.operationProfile")'
                  addon-left-icon="tim-icons icon-badge"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  <select class="form-control" v-model="form.operationProfile" @change="verifyPerfil()">
                    <option value=""> {{ $t("select") }} </option>
                    <option :value="4">Ultra agressivo</option>
                    <option :value="2">Agressivo</option>
                    <option :value="3">Moderado</option>
                    <option :value="1">Conservador</option>
                    <option :value="5">Ultra Conservador</option>
                  </select>
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.activeAnalysis")'
                  :label='$t("orders.activeAnalysis")'
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  autocomplete="off"
                  :label='$t("orders.activeAnalysis")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    <base-radio inline name="yes" class="mb-3" v-model="form.activeAnalysis">
                          {{ $t('yes') }}
                    </base-radio>

                    <base-radio inline name="no" class="mb-3" v-model="form.activeAnalysis">
                        {{ $t('no') }}
                    </base-radio>
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.savePartOfTheProfitInCurrency")'
                  :label='$t("orders.savePartOfTheProfitInCurrency")'
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  autocomplete="off"
                  :label='$t("orders.savePartOfTheProfitInCurrency")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    <base-radio inline name="yes" class="mb-3" v-model="form.savePartOfTheProfitInCurrency">
                          {{ $t('yes') }}
                    </base-radio>

                    <base-radio inline name="no" class="mb-3" v-model="form.savePartOfTheProfitInCurrency">
                        {{ $t('no') }}
                    </base-radio>
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("orders.securityLock")'
                  :label='$t("orders.securityLock")'
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  autocomplete="off"
                  :label='$t("orders.securityLock")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    <base-radio inline name="yes" class="mb-3" v-model="form.securityLock">
                          {{ $t('yes') }}
                    </base-radio>

                    <base-radio inline name="no" class="mb-3" v-model="form.securityLock">
                        {{ $t('no') }}
                    </base-radio>
                </base-input>
              </ValidationProvider>


              <errors :rows="getErrors()" />

              <base-button native-type="submit" type="primary" class="mb-3" v-if="onlyView === false" :disabled="invalid || requestOn || !someMarkedField" size="lg" block>
                {{ $t('update') }}
              </base-button>
            </form>
          </ValidationObserver>
        </div>
      </card>
    </div>

    <div class="col-md-6"  v-if="user && user.id">
      <card>
        <template slot="header">
          <h4 class="card-title"> {{ $t("orders.pars") }} </h4>
        </template>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-4">
              <strong class="text-success" v-html="$t('orderInfoLabel')"></strong>
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-4">
              <strong v-if="removed.length"> {{ $t("updated") }}: {{ removed.length }} <br /></strong>
              <span class="mr-2"> {{ massEditing.filter(m => !removed.includes(m.id)).map(o => o.symbolWithExchange).join(", ") }} </span>
            </div>
            <div class="mb-4">
              <strong class="text-success">
                {{ $t('orders.editTitle') }}
              </strong>
            </div>
          </div>

          <div class="col-md-12" v-if="oneEdit && form.value > 0 && form.operationsAllowed > 0">
            <div class="row">
              <div class="col-md-6">
                <div class="text-center mb-4">
                  <h5> {{ $t("currentAssetPrice") }}: ${{ onePrice }} </h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="text-center mb-4">
                  <h5> {{ $t("valueAllocatedInOrder", { value: valueAllocatedInOrder }) }} </h5>
                </div>
              </div>
              <div class="col-md-6">
                <div class="text-center mb-4">
                  {{ $t('dashboard.riskRate', { current: 'USD' }) }}
                  <strong :class="riskRateClass(riskRate())"> {{ riskRate() }}% </strong>
                </div>
              </div>
              <div class="col-md-6">
                <div class="text-center mb-4">
                  {{ $t('dashboard.riskRateExt', { current: 'USD' }) }}
                  <strong :class="riskRateClass(riskRate(valueNewRisk))"> {{ riskRate(valueNewRisk) }}% </strong>
                </div>
              </div>
              <div class="col-md-12 text-center">
                {{ $t("orders.viewLog") }}
              </div>
            </div>

            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col"> {{ $t("orders.noOfOrders") }} </th>
                  <th scope="col"> {{ $t("orders.assetQuotation") }} </th>
                  <th scope="col"> {{ $t("orders.betweenPurchases") }} </th>
                </tr>
              </thead>
                <tbody>
                  <tr :key="index" v-for="(quant, index) in operationsAllowedShow > 100 ? 100 : operationsAllowedShow">
                    <td> {{ quant }} </td><td v-html="forecast(quant, 1)"> </td><td v-html="forecast(quant, 2)">  </td>
                  </tr>
                </tbody>
            </table>
          </div>

        </div>
      </card>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import { required, email, min_value, max_value, numeric, regex } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { BaseAlert, BaseRadio } from '@/components'
import { listSymbols, userInfo, updateOrderCompany } from '@/services/index'
import Errors from '@/components/Errors/Index'
import balance from '../balance.vue'

extend("email", email);
extend("numeric", numeric);
extend("regex", regex);
extend("min_value", min_value);
extend("max_value", max_value);
extend("required", required);

function randomNumber(min, max) {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

function sleep(s) {
  return new Promise(resolve => setTimeout(resolve, s));
}

export default {
  props: ['ws'],
  components: {
    BaseAlert,
    BaseRadio,
    Errors,
    'company-user-balance': balance
  },
  data() {
    return {
      removed: [],
      requestOn: false,
      oneEdit: false,
      onePrice: 0,
      oneValue: 0,
      oneOperations: [],
      search: "",
      max_buy: 200,
      symbols: [],
      form: {
        buy_back: '',
        value: '',
        profit: '',
        profit_save: 30,
        par: "",
        operationProfile: '',
        operationsAllowed: '',
        maximumPurchasePrice: '',
        minimumPurchasePrice: '',
        savePartOfTheProfitInCurrency: "",
        securityLock: "",
        activeAnalysis: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      user: 'company/user',
      riskRate: "company/riskRate",
      onlyView: "user/onlyView"
    }),


    valueNewRisk: function() {
      if (this.form.buy_lock) return 0
      const opensValue = this.oneOperations.map(op => op.buy * op.amount_buy).reduce((a, b) => a + b, 0)
      if (opensValue > this.oneValue) return 0

      return parseFloat(this.valueAllocatedInOrder) - this.oneValue
    },

    massEditing: function() {
      return this.user.orders.filter(o => o.selected === true)
    },

    allowSymbol: function () {
      return this.symbols.filter(s => !this.user.orders.map(o => o.symbol_id).includes(s.id))
    },

    allowSymbolSelect: function () {
      return this.allowSymbol.filter(data => !this.search || data.symbolWithExchange.toLowerCase().includes(this.search.toLowerCase()))
    },

    exchange: function () {
      if (!this.form.par) return ""

      const symbol = this.symbols.find(s => s.id === this.form.par)
      return symbol.exchange || ""
    },

    someMarkedField: function() {
      let form = []
      for (const item in this.form) if (this.form[item] && item !== "profit_save"){
        form.push(this.form[item])
      }
      return form.length > 0
    },


    valueAllocatedInOrder: function() {
      const opensValue = this.oneOperations.map(op => op.buy * op.amount_buy).reduce((a, b) => a + b, 0)
      const nextAcount = this.form.operationsAllowed - this.oneOperations.length;
      const nextValue = (nextAcount <= 0) ? 0 : (nextAcount > 100) ? 100 * this.form.value : nextAcount * this.form.value
      return (opensValue + nextValue).toFixed(2)
    },

    operationsAllowedShow: function() {
      return this.oneOperations.length > parseInt(this.form.operationsAllowed) ? this.oneOperations.length : parseInt(this.form.operationsAllowed);
    },

  },
  methods: {
    changeSearch() {
      this.form.par = ""
    },

    riskRateClass(risk) {
      if (risk <= 30) return 'text-success';
      if (risk <= 50) return 'text-warning';
      return 'text-danger';
    },

    getPar(id) {
      return this.symbols.find(s => s.id === id) || {}
    },

    diffPercentage(value, percentage) {
      return (value - (value * (percentage / 100)));
    },


    forecast(q, t = 1) {

      const baseValue = this.oneOperations.length > 0 ? this.oneOperations[0].buy : this.onePrice

      const row = this.oneOperations[q - 1]
      if (row !== undefined && q === 1) return t === 1 ? `<strong>$${row.buy}</strong>` : `<strong>0%</strong>`
      if (row === undefined && q === 1) return t === 1 ? `$${this.onePrice}` : `0%`

      if (row !== undefined) {
        const percentage = ((row.buy/baseValue) * 100) - 100;
        return t === 1 ? `<strong>$${row.buy}</strong>` : `<strong>${percentage.toFixed(2)}%</strong>`
      }

      const buyBack = this.form.buy_back
      let newValue = this.oneOperations.length > 0 ? this.oneOperations[this.oneOperations.length - 1].buy : this.onePrice
      let percentage = 0

      const initValue = (this.oneOperations.length > 0) ? this.oneOperations.length : 1
      for (let i = initValue; i < q; i++) {
        newValue = this.diffPercentage(newValue, buyBack)
        percentage = ((newValue/baseValue) * 100) - 100;
      }

      return (t === 1) ? `$${parseFloat(newValue.toFixed(8))}` : `${parseFloat(percentage.toFixed(2))}%`
    },

    hasOrderOverview() {
      return this.form.par > 0 && this.form.operationsAllowed && this.form.value >= 3 && this.getPar(this.form.par).buyNow
    },

    onlyNumberDecimal(field) {
      this.form[field] = (this.form[field].match(/^\d+\.?\d{0,8}/) || [''])[0];
    },

    onlyNumber(field) {
      this.form[field] = (this.form[field].match(/^\d+\.?\d{0,0}/) || [''])[0];
    },

    verifyPerfil() {
      this.form.buy_back = this.buyBack(this.form.operationProfile)
    },

    buyBack(perfil) {
      switch (parseInt(perfil)) {
        case 5: // ultra Conservadora
        return randomNumber(31, 33)
        case 1: // Conservadora
          return randomNumber(18, 21)
        case 3: // Moderada
          return randomNumber(10.91, 11.9)
        case 2: // Agressivo
          return randomNumber(9.2, 10.90)
        case 4:
          return randomNumber(8.87, 9.01)
        default:
          return randomNumber(18, 21)
      }
    },

    async listSymbols() {
      this.requestOn = true
      try {
        const res = await listSymbols()
        this.symbols = res.data.map(s => ({ id: s.id, symbol: s.symbol, exchange: s.exchange, symbolWithExchange: `${s.exchange.toUpperCase()}-${s.symbol.toUpperCase()}`, buyNow: parseFloat(s.priceNow), currency: s.currency, group: s.group, min_value: s.min_value }))
      } catch (e) {
        console.error(e)
      }
      this.requestOn = false
    },

    async submitEdit() {
      let request = { }

      if (this.form.value !== "") {
        request.value = parseFloat(this.form.value)
      }

      if (this.form.operationsAllowed !== "") {
        request.max = parseInt(this.form.operationsAllowed)
      }

      if (this.form.profit !== "") {
        request.profit = parseFloat(this.form.profit)
      }

      if (this.form.minimumPurchasePrice !== "") {
        request.value_min = parseFloat(this.form.minimumPurchasePrice)
      }

      if (this.form.maximumPurchasePrice !== "") {
        request.value_max = parseFloat(this.form.maximumPurchasePrice)
      }

      if (this.form.operationProfile !== "") {
        this.verifyPerfil()
        request.profile = parseInt(this.form.operationProfile);
        request.buy_back = parseFloat(this.form.buy_back)
      }

      if (this.form.savePartOfTheProfitInCurrency !== "") {
        request.profit_save = this.form.savePartOfTheProfitInCurrency === 'yes' ? this.form.profit_save > 0 ? this.form.profit_save : 30 : 0;
      }

      if (this.form.securityLock !== "") {
        request.lockRed = this.form.securityLock === 'yes' ? true : false
      }

      if (this.form.activeAnalysis !== "") {
        request.activeAnalysis = this.form.activeAnalysis === 'yes' ? true : false
      }

      if (confirm(this.$t("doYouReally"))) {
        this.requestOn = true
          for (const index in this.massEditing) {
            try {
              await sleep(370)
              await updateOrderCompany(this.user.id, this.massEditing[index].id, request)
            } catch (e) {
              console.error(e)
            }
            this.removed.push(this.massEditing[index].id)
          }
          this.user.orders.forEach(o => o.ck = false)
          this.user.orders.forEach(o => o.selected = false)

          this.$notify({ type: 'success', message: this.$t("orders.requestEdit") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 15000 });
          setTimeout(() => (this.massEditing.length === 0) ? this.$router.back() : "", 3000)
        this.requestOn = false
      }
    }
  },
  async created() {
    this.companyVerifyUser()

    await this.listSymbols()
    const resUser = await userInfo()

    this.max_buy = resUser.data.max_buy;
  },

  mounted() {
    setTimeout(() => {
      if (this.massEditing.length === 0) {
        this.$router.back()
      } else if (this.massEditing.length === 1) {

        const copy = JSON.parse(JSON.stringify(this.massEditing[0]))
        this.oneOperations = copy.operations
        this.onePrice = copy.price
        this.form.value = copy.value
        this.form.currency = copy.currency
        this.form.buy_back = copy.buy_back
        this.form.profit = copy.profit
        this.form.profit_save = copy.profit_save
        this.form.operationProfile = copy.profile
        this.form.operationsAllowed = copy.max
        this.form.buy_lock = copy.buy_lock
        this.form.maximumPurchasePrice = copy.value_max
        this.form.minimumPurchasePrice = copy.value_min
        this.form.savePartOfTheProfitInCurrency = copy.profit_save > 0 ? 'yes': 'no'
        this.form.securityLock = copy.lockRed === false ? 'no' : 'yes'
        this.form.activeAnalysis = copy.activeAnalysis === false ? 'no' : 'yes'
        this.oneEdit = true

        const opensValue = this.oneOperations.map(op => op.buy * op.amount_buy).reduce((a, b) => a + b, 0)
        const nextAcount = this.form.operationsAllowed - this.oneOperations.length;
        const nextValue = (nextAcount <= 0) ? 0 : nextAcount * this.form.value
        this.oneValue = (opensValue + nextValue)
      }
    }, 2000)
  }
};
</script>
<style></style>
