<template>
  <div class="row">

    <company-user-balance />

    <div class="col-md-12" v-if="user && user.id">
      <card>
        <template slot="header">
          <h4 class="card-title">
            <router-link :to="{ name: 'CompanyUsers' }">
              <base-button class="ml-2" size="sm" simple type="success">
                {{ $t("return") }}
              </base-button>
            </router-link>

            {{ $t('orders.errors') }} ({{ ordersWithErrors.length }})
            <span v-if="search"> <badge type="warning"> {{ $t("filter") }}: {{ search }} </badge> <base-button size="sm" type="danger" @click="() => search='' "> X </base-button> </span>
          </h4>
        </template>
          <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
        <div v-if="ordersWithErrors.length && requestOn === false">
          <el-table
            class="table-striped"
            height="61vh"
            ref="multipleOrders"
            :default-sort="{ prop: 'status.value', order: 'descending' }"
            :data="ordersWithErrors.filter(data => !search || data.symbol.toLowerCase().includes(search.toLowerCase()))">

            <el-table-column type="expand">
              <template slot-scope="scope">
                <div class="row" v-if="scope.row.operations.length > 0">
                  <div class="col-md-12 mb-2"> <strong class="card-title"> {{ $t('openOperations.title') }} </strong> </div>
                  <div class="col-md-12 mb-2" :key="operation.id" v-for="operation of scope.row.operations">
                    <div class="col-md-12">
                      <span class="min-span"> {{ $t('orders.amount') }} : {{ operation.amount }} {{ scope.row.group }} </span>
                      <base-button class="ml-4" size="sm" simple type="danger" v-if="scope.row.inDifference < 0 && onlyView === false" @click="deleteOperation(scope.row, operation)" :disabled="scope.row.request">
                          {{ $t('delete') }}
                      </base-button>
                    </div>
                  </div>
                </div>
                <div class="row text-center" v-if="scope.row.operations.length === 0">
                  {{ $t("none") }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              color="primary"
              min-width="150"
              prop="symbol"
              :label="$t('orders.par')">

              <template slot="header">
                <base-input v-model="search"
                  type="text"
                  :placeholder="$t('openOperations.par')"/>
              </template>

            </el-table-column>

            <el-table-column
              color="primary"
              min-width="160"
              prop="wallet"
              sortable
              :label="$t('orders.wallet')">
            </el-table-column>

            <el-table-column
              color="primary"
              min-width="160"
              prop="inWallet"
              sortable
              :label="$t('orders.inOrder')">
            </el-table-column>

            <el-table-column
              color="primary"
              min-width="160"
              prop="inDifference"
              sortable
              :label="$t('orders.inDifference')">
            </el-table-column>

            <el-table-column
              color="primary"
              min-width="170"
              prop="messageError"
              sortable
              :label="$t('orders.message')">
            </el-table-column>

            <el-table-column
              color="primary"
              min-width="210"
              prop="action"
              sortable
              :label="$t('orders.clearErrors')">
              <template slot-scope="scope">
                <base-button class="ml-2 mb-2 mt-1" size="sm" simple
                  type="warning"
                  v-if="onlyView === false"
                  :disabled="scope.row.request"
                  @click="clearErrors(scope.row)">
                  {{ $t('orders.clearErrors') }}
                </base-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="text-center" v-if="ordersWithErrors.length === 0 && requestOn === false"> {{ $t('orders.none') }} </div>
      </card>
    </div>

  </div>
</template>
<script>

import { Table, TableColumn } from 'element-ui'
import { BaseAlert, BaseCheckbox, Badge, Modal } from '@/components'
import { listEventCompany } from '@/services/index'
import { mapGetters } from 'vuex'
import balance from '../balance.vue'

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseAlert,
    BaseCheckbox,
    Badge,
    Modal,
    'company-user-balance': balance
  },
  data() {
    return {
      requestOn: false,
      modalOpen: false,
      switchOn: false,
      uriView: '',
      search: ""
    };
  },
  computed: {
    ...mapGetters({
      user: 'company/user',
      onlyView: "user/onlyView"
    }),


    ordersWithErrors: function() {
      return this.user.orders.filter(o => o.countError >= 3)
    }

  },
  methods: {
    async clearErrors(row) {
      if (confirm(this.$t("doYouReally"))) {
        row.request = true
        try {
          const body = {
            action: "OrderCleanErrors",
            orderId: row.id
          }
          await listEventCompany(this.user.id, body)
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        } catch (e) {
          const { status, data = [] } = e.response || {}
          let message = e.message
          if (status === 422) {
            let erros = []
            for (const item in data) {
              erros.push(data[item].join("\n"))
            }
            message = erros.join("\n")
          }
          this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        }

        setTimeout(() => row.request = false, 10000);
      }
    },

    async deleteOperation(row, operation) {
      if (confirm(this.$t("doYouReally"))) {
        row.request = true
        try {
          const body = {
            action: "DeleteOperation",
            orderId: row.id,
            operationId: operation.id
          }
          await listEventCompany(this.user.id, body)
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });

        } catch (e) {
          const { status, data = [] } = e.response || {}
          let message = e.message
          if (status === 422) {
            let erros = []
            for (const item in data) {
              erros.push(data[item].join("\n"))
            }
            message = erros.join("\n")
          }
          this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        }
        setTimeout(() => row.request = false, 10000);
      }
    }

  },
  async created() {
    this.companyVerifyUser()

  },
  mounted() { }
};
</script>
<style></style>
