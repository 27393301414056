var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('h1',{staticStyle:{"display":"none"}},[_vm._v(" "+_vm._s(_vm.$t("meetNeiO"))+" ")]),_c('sidebar-share'),_c('div',{staticClass:"col-md-7 mr-auto ml-auto mt-4"},[_c('back-to-login'),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{staticClass:"card-body"},[_c('template',{slot:"header"},[_c('h1',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t("register.register"))+" ")])]),_c('ValidationProvider',{attrs:{"name":_vm.$t("register.nickname"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","autocomplete":"off","required":"","placeholder":_vm.$t("register.nickname"),"addon-left-icon":"tim-icons icon-single-02","error":errors[0]},model:{value:(_vm.form.nickname),callback:function ($$v) {_vm.$set(_vm.form, "nickname", $$v)},expression:"form.nickname"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("register.email"),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"email","autocomplete":"off","required":"","placeholder":_vm.$t("register.email"),"addon-left-icon":"tim-icons icon-email-85","error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("register.password"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"password","autocomplete":"off","required":"","placeholder":_vm.$t("register.password"),"addon-left-icon":"tim-icons icon-lock-circle","error":errors[0]},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('base-input',{attrs:{"readonly":"readonly","autocomplete":"off","required":"","placeholder":_vm.$t("register.referralCode"),"addon-left-icon":"tim-icons icon-single-02"},model:{value:(_vm.form.referralCode),callback:function ($$v) {_vm.$set(_vm.form, "referralCode", $$v)},expression:"form.referralCode"}}),_c('errors',{attrs:{"rows":_vm.getErrors()}}),_c('ValidationProvider',{attrs:{"name":"terms","rules":"required|bool"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-checkbox',{staticClass:"text-left",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"checkbox","error":errors[0]},model:{value:(_vm.form.terms),callback:function ($$v) {_vm.$set(_vm.form, "terms", $$v)},expression:"form.terms"}},[_vm._v(" "+_vm._s(_vm.$t("terms.n1"))+" "),_c('a',{staticClass:"d-text d-s",attrs:{"href":"https://www.neio.tech/politica-privacidade-neio","target":"black_private"}},[_vm._v(" "+_vm._s(_vm.$t("terms.n2"))+" ")]),_vm._v(", "+_vm._s(_vm.$t("terms.n3"))+" "),_c('a',{staticClass:"d-text d-s",attrs:{"href":"https://www.neio.tech/termo-de-uso-neio","target":"black_terms"}},[_vm._v(" "+_vm._s(_vm.$t("terms.n4"))+" ")])])]}}],null,true)}),_c('base-button',{attrs:{"slot":"footer","native-type":"submit","type":"primary","disabled":invalid || _vm.requestOn,"round":"","block":"","size":"lg"},slot:"footer"},[_vm._v(" "+_vm._s(_vm.$t("register.submit"))+" ")])],2)],1)]}}])}),_c('networks'),(_vm.modalOpen)?_c('modal',{attrs:{"show":_vm.modalOpen},on:{"update:show":function($event){_vm.modalOpen=$event}}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"modal-title text-success"},[_vm._v(" "+_vm._s(_vm.$t("Registration completed successfully"))+" ")])]),_c('div',{staticClass:"embed-responsive mb-5",staticStyle:{"font-size":"1.1em"}},[_vm._v(" "+_vm._s(_vm.$t("messageRegister"))+" ")]),_c('router-link',{staticClass:"text-warning",attrs:{"to":"login"}},[_vm._v(" "+_vm._s(_vm.$t("close to login"))+" ")]),_c('template',{slot:"footer"})],2):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }