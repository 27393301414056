<template>
  <div class="row">
    <balance :ws="ws" />

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4">
          <card>
             <template slot="header">
              <h4 class="card-title">
                {{ $t('orders.massEditing') }}

                <router-link :to="{ name: 'Orders' }">
                  <base-button class="ml-2" size="sm" simple type="success">
                    {{ $t("return") }}
                  </base-button>
                </router-link>

              </h4>
             </template>
            <div class="card-body">
              <div v-if="blocked === false">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
                  <form @submit.prevent="handleSubmit(submitEditOrder)">

                    <ValidationProvider
                        :name='$t("orders.value")'
                        :rules="{ regex: /^\d+\.?\d{0,8}/, min_value: minValue, max_value: max_buy }"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        v-model="form.value"
                        autocomplete="off"
                        :label='`${$t("orders.value")}`'
                        addon-left-icon="tim-icons icon-money-coins"
                        :error="errors[0]"
                        @input="onlyNumberDecimal('value')"
                        @change="onlyNumberDecimal('value')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.operationsAllowed")'
                        :rules="{ regex: /^\d+\.?\d{0,0}/, min_value:1 , max_value: 100 }"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        v-model="form.operationsAllowed"
                        autocomplete="off"
                        :label='$t("orders.operationsAllowed")'
                        addon-left-icon="tim-icons icon-notes"
                        :error="errors[0]"
                        @input="onlyNumber('operationsAllowed')"
                        @change="onlyNumber('operationsAllowed')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.expectedProfit")'
                        :rules="{ regex: /^\d+\.?\d{0,2}/, min_value: showAdmin ? 0.7 : 1, max_value: 4.07 }"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        v-model="form.profit"
                        autocomplete="off"
                        :label='$t("orders.expectedProfit")'
                        addon-left-icon="tim-icons icon-chart-pie-36"
                        :error="errors[0]"
                        @input="onlyNumberDecimal('profit')"
                        @change="onlyNumberDecimal('profit')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>


                    <ValidationProvider
                        :name='$t("orders.percentageSavedInCoin")'
                        :rules="{ regex: /^\d+\.?\d{0,2}/, min_value:0, max_value: showAdmin ? 200 : 100 }"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        v-model="form.profit_save"
                        autocomplete="off"
                        :label='$t("orders.percentageSavedInCoin")'
                        addon-left-icon="tim-icons icon-chart-pie-36"
                        :error="errors[0]"
                        @input="onlyNumberDecimal('profit_save')"
                        @change="onlyNumberDecimal('profit_save')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>



                    <base-alert type="warning" v-if="hasRealProfit()">
                       {{ $t("orders.expectedProfit") }} + {{ $t("orders.percentageSavedInCoin") }} = <strong> {{ realProfit() }} </strong>
                    </base-alert>

                    <ValidationProvider
                        :name='$t("orders.maximumPurchasePrice")'
                        rules=""
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        v-model="form.maximumPurchasePrice"
                        autocomplete="off"
                        :label='$t("orders.maximumPurchasePrice")'
                        addon-left-icon="tim-icons icon-lock-circle"
                        :error="errors[0]"
                        @input="onlyNumberDecimal('maximumPurchasePrice')"
                        @change="onlyNumberDecimal('maximumPurchasePrice')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.minimumPurchasePrice")'
                        rules=""
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        v-model="form.minimumPurchasePrice"
                        autocomplete="off"
                        :label='$t("orders.minimumPurchasePrice")'
                        addon-left-icon="tim-icons icon-lock-circle"
                        :error="errors[0]"
                        @input="onlyNumberDecimal('minimumPurchasePrice')"
                        @change="onlyNumberDecimal('minimumPurchasePrice')"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.operationProfile")'
                        rules=""
                        v-slot="{ passed, failed, errors }"
                      >

                      <p>
                        <label>
                          {{ $t("orders.operationProfile") }}
                          <span class="" data-toggle="tooltip" data-placement="top" :title="$t('note1')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                            </svg>
                          </span>
                        </label>
                      </p>
                      <base-input
                        autocomplete="off"
                        addon-left-icon="tim-icons icon-badge"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                        <select class="form-control" v-model="form.operationProfile" @change="verifyPerfil()">
                          <option value=""> {{ $t("select") }} </option>
                          <option :value="4">Ultra agressivo</option>
                          <option :value="2">Agressivo</option>
                          <option :value="3">Moderado</option>
                          <option :value="1">Conservador</option>
                          <option :value="5">Ultra Conservador</option>
                        </select>
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.activeAnalysis")'
                        :label='$t("orders.activeAnalysis")'
                        rules=""
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        autocomplete="off"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          <p class="sub-marge">
                            <label>
                              {{ $t("orders.activeAnalysis") }}
                              <span class="" data-toggle="tooltip" data-placement="top" :title="$t('note2')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                </svg>
                              </span>
                            </label>
                          </p>

                          <base-radio inline name="yes" class="mb-2" v-model="form.activeAnalysis">
                               {{ $t('yes') }}
                          </base-radio>

                          <base-radio inline name="no" class="mb-2" v-model="form.activeAnalysis">
                              {{ $t('no') }}
                          </base-radio>
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("orders.securityLock")'
                        :label='$t("orders.securityLock")'
                        rules=""
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        autocomplete="off"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">

                          <p class="sub-marge">
                            <label>
                              {{ $t("orders.securityLock") }}
                              <span class="" data-toggle="tooltip" data-placement="top" :title="$t('note3')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                                </svg>
                              </span>
                            </label>
                          </p>

                          <base-radio inline name="yes" class="mb-2" v-model="form.securityLock">
                               {{ $t('yes') }}
                          </base-radio>

                          <base-radio inline name="no" class="mb-2" v-model="form.securityLock">
                              {{ $t('no') }}
                          </base-radio>
                      </base-input>
                    </ValidationProvider>


                    <errors :rows="getErrors()" />

                    <base-button native-type="submit" v-if="onlyView === false" type="primary" class="mb-3" :disabled="invalid || !someMarkedField || requestOn" size="lg" block>
                      {{ $t('update') }}
                    </base-button>
                  </form>
                </ValidationObserver>
              </div>
              <div v-if="blocked === true && requestOn === false">
                <base-alert type="danger">
                  {{ $t('orders.deactivatedAccount') }}
                </base-alert>
              </div>

            </div>
          </card>
        </div>

        <div class="col-md-8">
          <card v-if="massEditing.length === 1">
            <div class="card-body">
              <trading-view
                :symbol="massEditing[0].symbol"
                :exchange="massEditing[0].exchange"
              />
            </div>
          </card>

          <card>
            <div class="card-body">
              <h4 class="card-title"> {{ $t("orders.pars") }} </h4>

              <div v-if="blocked === true">
                <base-alert type="danger">
                  {{ $t('orders.deactivatedAccount') }}
                </base-alert>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="mb-2">
                    <strong v-if="removed.length"> {{ $t("updated") }}: {{ removed.length }} <br /></strong>
                    <span class="mr-2"> {{ massEditing.filter(m => !removed.includes(m.id)).map(o => o.symbolWithExchange).join(", ") }} </span>
                  </div>
                </div>
                <div class="col-md-12" v-if="oneEdit && form.value > 0 && form.operationsAllowed > 0">
                  <div class="row">
                    <div class="col-md-6">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="text-center"> {{ $t("currentAssetPrice") }} </th>
                            <th class="text-center"> {{ $t("valueInOrder") }} </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center"> ${{ onePrice }} </td>
                            <td class="text-center"> ${{  valueAllocatedInOrder }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="text-center"> {{ $t('dashboard.riskRateIn') }} </th>
                            <th class="text-center"> {{ $t("dashboard.riskRateExtIn") }} </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center"> <strong :class="riskRateClass(riskRateRender())"> {{ riskRateRender() }}% </strong></td>
                            <td class="text-center"> <strong :class="riskRateClass(riskRateExRender(valueNewRisk))"> {{ riskRateExRender(valueNewRisk) }}% </strong> </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="col-md-6">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th colspan="3" class="text-center"> {{ $t("orders.viewLog") }} </th>
                          </tr>
                          <tr>
                            <th scope="col"> {{ $t("orders.noOfOrders") }} </th>
                            <th scope="col"> {{ $t("orders.assetQuotation") }} </th>
                            <th scope="col"> {{ $t("orders.betweenPurchases") }} </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr :key="index" v-for="(quant, index) in operationsAllowedShow > 100 ? 100 : operationsAllowedShow">
                            <td> {{ quant }} </td><td v-html="forecast(quant, 1)"> </td><td v-html="forecast(quant, 2)">  </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import { mapGetters } from 'vuex'
import { editOrder, userInfo, listSymbols } from "@/services"
import { BaseAlert, BaseRadio } from 'src/components'
import { extend } from "vee-validate";
import { required, email, min_value, max_value, numeric, regex } from "vee-validate/dist/rules";
import Errors from '@/components/Errors/Index'
import TradingView from "@/components/TradingView"

extend("email", email);
extend("numeric", numeric);
extend("regex", regex);
extend("min_value", min_value);
extend("max_value", max_value);
extend("required", required);

function randomNumber(min, max) {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

function sleep(s) {
  return new Promise(resolve => setTimeout(resolve, s));
}


export default {
  props: ['ws'],
  components: {
    BaseAlert,
    BaseRadio,
    Errors,
    TradingView
  },
  data() {
    return {
      removed: [],
      requestOn: false,
      oneEdit: false,
      onePrice: 0,
      oneValue: 0,
      oneOperations: [],
      search: "",
      max_buy: 200,
      symbols: [],
      form: {
        buy_back: '',
        value: '',
        profit: '',
        profit_save: '',
        par: "",
        operationProfile: '',
        operationsAllowed: '',
        maximumPurchasePrice: '',
        minimumPurchasePrice: '',
        savePartOfTheProfitInCurrency: "",
        securityLock: "",
        activeAnalysis: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      riskRate: "user/riskRate",
      riskRateBrl: "user/riskRateBrl",
      riskRateExt: "user/riskRateExt",
      riskRateExtBrl: "user/riskRateExtBrl",
      orders: 'user/orders',
      exchange: 'user/exchange',
      blocked: "user/blocked",
      onlyView: "user/onlyView",
      showAdmin: 'user/showAdmin',
    }),

    uriView() {
      return ''

      // this.uriView = `https://s.tradingview.com/widgetembed/?frameElementId=tradingview_a6e15&symbol=${row.exchange}%3A${row.symbol.replace('/', '').replace('-', '')}&interval=1D&hidesidetoolbar=0&symboledit=1&saveimage=1&toolbarbg=f1f3f6&studies=%5B%5D&theme=dark&style=1&timezone=Etc%2FUTC&withdateranges=1&showpopupbutton=1&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&showpopupbutton=1&locale=en&utm_source=tradio-vuejs.vercel.app&utm_medium=widget&utm_campaign=chart&utm_term=${row.exchange}%3A${row.symbol.replace('/', '').replace('-', '')}`

    },

    ordersActive: function() {
      return this.orders.filter(o => o.show)
    },

    operationsAllowedShow: function() {
      return this.oneOperations.length > parseInt(this.form.operationsAllowed) ? this.oneOperations.length : parseInt(this.form.operationsAllowed);
    },

    massEditing: function() {
      return this.orders.filter(o => o.selected === true)
    },

    someMarkedField: function() {
      return this.form.activeAnalysis !== "" || this.form.value !== "" || this.form.operationsAllowed !== "" || this.form.profit !== ""  || this.form.profit_save !== "" || this.form.minimumPurchasePrice !== "" || this.form.maximumPurchasePrice !== "" || this.form.operationProfile !== "" || this.form.savePartOfTheProfitInCurrency !== "" || this.form.securityLock !== ""
    },

    allowSymbol: function() {
      return this.symbols.filter(s => s.exchange === this.exchange && !this.orders.map(o => o.symbol_id).includes(s.id))
    },

    minValue: function() {
      const item = this.symbols.filter(s => s.exchange === this.exchange)

      if (item.length > 0) {
        return item[0].min_value
      }

      return 20
    },
    valueAllocatedInOrder: function() {
      const opensValue = this.oneOperations.map(op => op.buy * op.amount_buy).reduce((a, b) => a + b, 0)
      const nextAcount = this.form.operationsAllowed - this.oneOperations.length;
      const nextValue = (nextAcount <= 0) ? 0 : (nextAcount > 100) ? 100 * this.form.value : nextAcount * this.form.value
      return (opensValue + nextValue).toFixed(2)
    },

    valueNewRisk: function() {
      if (this.form.buy_lock) return 0
      const opensValue = this.oneOperations.map(op => op.buy * op.amount_buy).reduce((a, b) => a + b, 0)
      if (opensValue > this.oneValue) return 0

      return parseFloat(this.valueAllocatedInOrder) - this.oneValue
    }
  },
  methods: {

    currentVerify() {
      return this.form.currency === 'brl' ? "BRL" : "USD"
    },

    hasRealProfit() {
      return ((parseFloat(this.form.profit) || 0) > 0 && parseFloat(this.form.profit) <= 4.07 && (parseFloat((this.form.profit_save) || 0)) > 0 && (parseFloat(this.form.profit_save) || 0) <= (this.showAdmin ? 200 : 100))
    },

    realProfit() {
      let text = this.$t('orders.realMinimumProfit')

      if ((parseFloat(this.form.profit) || 0) > 0 && ((parseFloat(this.form.profit_save) || 0) <= 0 || (parseFloat(this.form.profit_save) || 0) > (this.showAdmin ? 200 : 100)))
        text = text + ' ' + parseFloat((this.form.profit))

      if ((parseFloat(this.form.profit) || 0) > 0 && (parseFloat((this.form.profit_save) || 0)) > 0) {
        const value = (parseFloat(this.form.profit) + ((parseFloat((this.form.profit)) * parseFloat(this.form.profit_save)) / 100))
        text = text + ' ' + value.toFixed(2);
      }

      return text + '%';

    },


    riskRateClass(risk) {
      if (risk <= 30) return 'text-success';
      if (risk <= 50) return 'text-warning';
      return 'text-danger';
    },

    riskRateRender() {
      return this.currentVerify() === "BRL" ? this.riskRateBrl : this.riskRate
    },

    riskRateExRender(value) {
      return this.currentVerify() === "BRL" ? this.riskRateExtBrl(value) : this.riskRateExt(value)
    },

    async listUser() {
      this.requestOn = true
      try {
        const res = await listSymbols()
        this.symbols = res.data.map(s => ({ id: s.id, symbol: s.symbol, exchange: s.exchange, symbolWithExchange: `${s.exchange.toUpperCase()}-${s.symbol.toUpperCase()}`, buyNow: parseFloat(s.priceNow), currency: s.currency, group: s.group, min_value: s.min_value }))

        const resUser = await userInfo()
        this.max_buy = resUser.data.max_buy;
      } catch (e) {
        console.error(e)
      }
      this.requestOn = false
    },

    onlyNumberDecimal(field) {
      this.form[field] = (this.form[field].match(/^\d+\.?\d{0,8}/) || [''])[0];
    },

    onlyNumber(field) {
      this.form[field] = (this.form[field].match(/^\d+\.?\d{0,0}/) || [''])[0];
    },

    buyBack(perfil) {
      switch (parseInt(perfil)) {
        case 5: // ultra Conservadora
        return randomNumber(25, 33)
        case 1: // Conservadora
          return randomNumber(18, 21)
        case 3: // Moderada
          return randomNumber(9, 11)
        case 2: // Agressivo
          return randomNumber(6, 8)
        case 4: // ultra Agressivo
          return randomNumber(4.3, 5)
        default:
          return randomNumber(9, 11) // default
      }
    },

    verifyPerfil() {
      if (this.form.operationProfile === "") {
        return
      }
      this.form.buy_back = this.buyBack(this.form.operationProfile)
    },


    diffPercentage(value, percentage) {
      return (value - (value * (percentage / 100)));
    },


    forecast(q, t = 1) {

      const baseValue = this.oneOperations.length > 0 ? this.oneOperations[0].buy : this.onePrice

      const row = this.oneOperations[q - 1]
      if (row !== undefined && q === 1) return t === 1 ? `<strong>$${row.buy}</strong>` : `<strong>0%</strong>`
      if (row === undefined && q === 1) return t === 1 ? `$${this.onePrice}` : `0%`

      if (row !== undefined) {
        const percentage = ((row.buy/baseValue) * 100) - 100;
        return t === 1 ? `<strong>$${row.buy}</strong>` : `<strong>${percentage.toFixed(2)}%</strong>`
      }

      const buyBack = this.form.buy_back
      let newValue = this.oneOperations.length > 0 ? this.oneOperations[this.oneOperations.length - 1].buy : this.onePrice
      let percentage = 0

      const initValue = (this.oneOperations.length > 0) ? this.oneOperations.length : 1
      for (let i = initValue; i < q; i++) {
        newValue = this.diffPercentage(newValue, buyBack)
        percentage = ((newValue/baseValue) * 100) - 100;
      }

      return (t === 1) ? `$${parseFloat(newValue.toFixed(8))}` : `${parseFloat(percentage.toFixed(2))}%`
    },

    async submitEditOrder() {
      let request = { }

      if (this.form.value !== "") {
        request.value = parseFloat(this.form.value)
      }

      if (this.form.operationsAllowed !== "") {
        request.max = parseInt(this.form.operationsAllowed)
      }

      if (this.form.profit !== "") {
        request.profit = parseFloat(this.form.profit)
      }

      if (this.form.minimumPurchasePrice !== "") {
        request.value_min = parseFloat(this.form.minimumPurchasePrice)
      }

      if (this.form.maximumPurchasePrice !== "") {
        request.value_max = parseFloat(this.form.maximumPurchasePrice)
      }

      if (this.form.operationProfile !== "") {
        this.verifyPerfil()
        request.perfil = parseInt(this.form.operationProfile);
        request.buy_back = parseFloat(this.form.buy_back)
      }

      if (this.form.profit_save !== "") {
        request.profit_save = this.form.profit_save;
      }

      if (this.form.securityLock !== "") {
        request.lockRed = this.form.securityLock === 'yes' ? true : false
      }

      if (this.form.activeAnalysis !== "") {
        request.activeAnalysis = this.form.activeAnalysis === 'yes' ? true : false
      }

      request.stop = 0.25

      if (confirm(this.$t("doYouReally"))) {
        this.requestOn = true
          for (const index in this.massEditing) {
            try {
              await sleep(500)
              await editOrder(this.massEditing[index].id, request)
            } catch (e) {
              console.error(e)
            }
            this.removed.push(this.massEditing[index].id)
          }
          this.orders.forEach(o => o.ck = false)
          this.orders.forEach(o => o.selected = false)

          this.$notify({ type: 'success', message: this.$t("orders.requestEdit") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 15000 });
          setTimeout(() => (this.massEditing.length === 0) ? this.$router.back() : "", 3000)
        this.requestOn = false
      }
    }
  },
  created() {
    this.listUser()
  },
  mounted() {
    setTimeout(() => {
      if (this.massEditing.length === 0) {
        this.$router.back()
      } else if (this.massEditing.length === 1) {

        const copy = JSON.parse(JSON.stringify(this.massEditing[0]))
        this.oneOperations = copy.operations
        this.onePrice = copy.price
        this.form.value = copy.value
        this.form.currency = copy.currency
        this.form.buy_back = copy.buy_back
        this.form.profit = copy.profit
        this.form.profit_save = copy.profit_save
        this.form.operationProfile = copy.perfil
        this.form.operationsAllowed = copy.max
        this.form.buy_lock = copy.buy_lock
        this.form.maximumPurchasePrice = copy.value_max
        this.form.minimumPurchasePrice = copy.value_min
        this.form.savePartOfTheProfitInCurrency = copy.profit_save > 0 ? 'yes': 'no'
        this.form.securityLock = copy.lockRed === false ? 'no' : 'yes'
        this.form.activeAnalysis = copy.activeAnalysis === false ? 'no' : 'yes'
        this.oneEdit = true

        const opensValue = this.oneOperations.map(op => op.buy * op.amount_buy).reduce((a, b) => a + b, 0)
        const nextAcount = this.form.operationsAllowed - this.oneOperations.length;
        const nextValue = (nextAcount <= 0) ? 0 : nextAcount * this.form.value
        this.oneValue = (opensValue + nextValue)
      }
    }, 2000)
  }
};
</script>
<style></style>
