var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('sidebar-share'),_c('div',{staticClass:"col-md-10 mr-auto ml-auto mt-4"},[_c('back-to-login'),_c('card',{staticClass:"card-body"},[_c('div',{staticClass:"col-md-12"},[_c('tabs',{staticClass:"row",attrs:{"type":"primary","vertical":""}},[_c('tab-pane',{attrs:{"label":_vm.$t("resetPassword.requestRecoveryCode")}},[_c('card',{staticClass:"card-body"},[_c('template',{slot:"header"},[_c('h3',{staticClass:"card-title pl-2"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword.requestRecoveryCode"))+" ")]),_c('h4',{staticClass:"card-title pl-2"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword.requestRecoveryCodeSubTitle"))+" ")])]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t("register.email"),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":_vm.$t("register.email"),"addon-left-icon":"tim-icons icon-email-85","type":"email","error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('base-button',{attrs:{"slot":"footer","native-type":"submit","type":"primary","disabled":invalid || _vm.requestOn,"round":"","block":"","size":"lg"},slot:"footer"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword.request"))+" ")])],1)]}}])})],2)],1),_c('tab-pane',{attrs:{"label":_vm.$t("resetPassword.haveRecoveryCode")}},[_c('card',{staticClass:"card-body"},[_c('template',{slot:"header"},[_c('h3',{staticClass:"card-title pl-2"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword.haveRecoveryCode"))+" ")]),_c('h4',{staticClass:"card-title pl-2"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword.haveRecoveryCodeSubTitle"))+" ")])]),_c('ValidationObserver',{ref:"formAlter",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.alterSubmit)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t("resetPassword.recoveryCode"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":_vm.$t("resetPassword.recoveryCode"),"addon-left-icon":"tim-icons icon-lock-circle","error":errors[0]},model:{value:(_vm.form.recoveryCode),callback:function ($$v) {_vm.$set(_vm.form, "recoveryCode", $$v)},expression:"form.recoveryCode"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("resetPassword.password"),"vid":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"addon-left-icon":"tim-icons icon-lock-circle","type":"password","autocomplete":"off","required":"","placeholder":_vm.$t("resetPassword.password"),"error":errors[0]},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("resetPassword.confirmPassword"),"rules":"required|min:5|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","type":"password","addon-left-icon":"tim-icons icon-lock-circle","required":"","placeholder":_vm.$t("resetPassword.confirmPassword"),"error":errors[0]},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})]}}],null,true)}),_c('base-button',{attrs:{"slot":"footer","native-type":"submit","type":"primary","disabled":invalid || _vm.requestOn,"round":"","block":"","size":"lg"},slot:"footer"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword.changePassword"))+" ")])],1)]}}])})],2)],1)],1)],1)]),_c('networks')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }