<template>
  <card>
    <template slot="header">
      <h4 class="card-title"> {{ $t("payment.walletBalance") }}  </h4>
    </template>

    <div class="card-body">
      <div>
        <h3 :class="setClass()">
          {{ $t('payment.value', { value, type }) }}
          <span class="badge badge-secondary copyOne" @click="modelExtract">  {{ $t('extract.extract') }}  </span>
        </h3>

        <base-button
          block
          native-type="submit"
          type="warning"
          size="sm"
          class="btn btn-round"
          v-if="showRequestWithdrawal"
          @click="request()"
          :disabled="requestOn">
          {{ $t('Request Withdrawal') }}
        </base-button>

        <base-alert
          type="success"
          v-if="profile.requestWithdrawal">
          {{ $t("requestWithdrawalInfo") }}
        </base-alert>
      </div>

      <modal size="xl" v-if="modalOpen" :show.sync="modalOpen">
        <extract />
      </modal>
    </div>
  </card>
</template>
<script>
import { updateUser, userData } from '@/services'
import { BaseAlert, Modal } from '@/components'
import { mapGetters, mapActions } from 'vuex'
import Extract from './extract.vue'

export default {
  props: [],
  components: {
    BaseAlert,
    Modal,
    Extract
  },
  data() {
    return {
      requestOn: false,
      modalOpen: false
    }
  },
  computed: {
    ...mapGetters({
      situation: 'userState/situation',
      profile: 'userState/profile'
    }),

    value() {
      return this.situation.usd
    },

    type() {
      return this.situation.type === 'd'
        ? this.$t('payment.debt')
        : this.$t('payment.credit')
    },

    showRequestWithdrawal() {
      return this.profile.requestWithdrawal === false && this.situation.type !== "d" && this.value >= 10
    }
  },
  created() {
  },
  methods: {
    ...mapActions({
      setProfile: "userState/setProfile",
    }),

    setClass() {
      return this.situation.type !== 'd' ? 'text-success' : 'text-danger'
    },

    modelExtract() {
      this.modalOpen = true
    },

    async request() {
      if (confirm(this.$t("requestWithdrawalConfirm", { value: this.value }))) {
        this.requestOn = true

        try {

          await updateUser({ requestWithdrawal: true })
          const data = await userData()
          this.setProfile(data)

        } catch (e) {
          alert(e.message)
        }

        this.requestOn = false
      }
    },
  }
};
</script>
