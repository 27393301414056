var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('balance',{attrs:{"ws":_vm.ws}}),_c('brokerage-situation',{attrs:{"ws":_vm.ws}}),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',{attrs:{"title":_vm.$t('contactUs.important')}},[_c('p',{staticClass:"pr-5",domProps:{"innerHTML":_vm._s(_vm.$t('contactUs.note', { uri: _vm.uri }))}})])],1),_c('div',{staticClass:"col-md-9"},[_c('card',{attrs:{"title":_vm.$t('contactUs.title')}},[_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [(_vm.onlyView === false)?_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card-body"},[_c('div',[_c('ValidationProvider',{attrs:{"name":_vm.$t("contactUs.subject"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","autocomplete":"off","placeholder":_vm.$t("contactUs.subject"),"error":errors[0]},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("contactUs.message"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"error":errors[0]}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.text),expression:"form.text"}],staticClass:"form-control",attrs:{"rows":"7","required":"","autocomplete":"off","placeholder":_vm.$t("contactUs.message")},domProps:{"value":(_vm.form.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "text", $event.target.value)}}})])]}}],null,true)})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('base-button',{staticClass:"mb-3",attrs:{"native-type":"submit","type":"primary","disabled":invalid || _vm.requestOn,"size":"lg","block":""}},[_vm._v(" "+_vm._s(_vm.$t("contactUs.send"))+" "),_c('span',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.requestOn),expression:"requestOn"}],staticClass:"spinner-grow"})])],1)])]):_vm._e()]}}])})],1)])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }