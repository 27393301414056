var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('card',[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_c('router-link',{attrs:{"to":{ name: 'CompanyUsers' }}},[_c('base-button',{staticClass:"ml-2",attrs:{"size":"sm","simple":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.$t("return"))+" ")])],1),_vm._v(" "+_vm._s(_vm.$t("newUser"))+" ")],1)]),_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitNewUser)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.name"),"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","autocomplete":"off","label":_vm.$t("companyUser.name"),"error":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.apiBinance"),"rules":{ min: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","label":_vm.$t("companyUser.apiBinance"),"error":errors[0]},model:{value:(_vm.form.apiBinance),callback:function ($$v) {_vm.$set(_vm.form, "apiBinance", $$v)},expression:"form.apiBinance"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.secretBinance"),"rules":{ min: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","label":_vm.$t("companyUser.secretBinance"),"error":errors[0]},model:{value:(_vm.form.secretBinance),callback:function ($$v) {_vm.$set(_vm.form, "secretBinance", $$v)},expression:"form.secretBinance"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.apiHuobi"),"rules":{ min: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","label":_vm.$t("companyUser.apiHuobi"),"error":errors[0]},model:{value:(_vm.form.apiHuobi),callback:function ($$v) {_vm.$set(_vm.form, "apiHuobi", $$v)},expression:"form.apiHuobi"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.secretHuobi"),"rules":{ min: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","label":_vm.$t("companyUser.secretHuobi"),"error":errors[0]},model:{value:(_vm.form.secretHuobi),callback:function ($$v) {_vm.$set(_vm.form, "secretHuobi", $$v)},expression:"form.secretHuobi"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.apiKucoin"),"rules":{ min: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","label":_vm.$t("companyUser.apiKucoin"),"error":errors[0]},model:{value:(_vm.form.apiKucoin),callback:function ($$v) {_vm.$set(_vm.form, "apiKucoin", $$v)},expression:"form.apiKucoin"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.secretKucoin"),"rules":{ min: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","label":_vm.$t("companyUser.secretKucoin"),"error":errors[0]},model:{value:(_vm.form.secretKucoin),callback:function ($$v) {_vm.$set(_vm.form, "secretKucoin", $$v)},expression:"form.secretKucoin"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.passphraseKucoin"),"rules":{ min: 3 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","label":_vm.$t("companyUser.passphraseKucoin"),"error":errors[0]},model:{value:(_vm.form.passphraseKucoin),callback:function ($$v) {_vm.$set(_vm.form, "passphraseKucoin", $$v)},expression:"form.passphraseKucoin"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.apiOkex"),"rules":{ min: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","label":_vm.$t("companyUser.apiOkex"),"error":errors[0]},model:{value:(_vm.form.apiOkex),callback:function ($$v) {_vm.$set(_vm.form, "apiOkex", $$v)},expression:"form.apiOkex"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.secretOkex"),"rules":{ min: 7 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","label":_vm.$t("companyUser.secretOkex"),"error":errors[0]},model:{value:(_vm.form.secretOkex),callback:function ($$v) {_vm.$set(_vm.form, "secretOkex", $$v)},expression:"form.secretOkex"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t("companyUser.passphraseOkex"),"rules":{ min: 3 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"autocomplete":"off","label":_vm.$t("companyUser.passphraseOkex"),"error":errors[0]},model:{value:(_vm.form.passphraseOkex),callback:function ($$v) {_vm.$set(_vm.form, "passphraseOkex", $$v)},expression:"form.passphraseOkex"}})]}}],null,true)}),(_vm.onlyView === false)?_c('base-button',{staticClass:"mb-3",attrs:{"native-type":"submit","type":"primary","disabled":invalid || _vm.requestOn,"size":"lg","block":""}},[_vm._v(" "+_vm._s(_vm.$t('orders.createOrder'))+" ")]):_vm._e()],1)]}}])})],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }