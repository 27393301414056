<template>
  <div class="row">

    <company-user-balance />

    <div class="col-md-12"  v-if="user && user.id">
      <card>
        <template slot="header">
          <h4 class="card-title">

            <router-link :to="{ name: 'CompanyUsers' }">
              <base-button class="ml-2" size="sm" simple type="success">
                {{ $t("return") }}
              </base-button>
            </router-link>

            {{ $t('openOperations.title') }} ({{ operations.length }})
            <span v-if="search"> <badge type="warning"> {{ $t("filter") }}: {{ search }} </badge> <base-button size="sm" type="danger" @click="() => search='' "> X </base-button> </span>

          </h4>
        </template>

        <el-table class="table-striped" v-if="operations.length"
          height="61vh"
          :default-sort="{ prop: 'situation', order: 'descending' }"
          :data="operations.filter(data => !search || data.symbol.toLowerCase().includes(search.toLowerCase()))">
          <el-table-column
            color="primary"
            style="opacity: 0;"
            width="160"
            prop="symbol"
            :label="$t('openOperations.par')">

            <template slot="header">
              <base-input v-model="search"
                type="text"
                :placeholder="$t('openOperations.par')"/>
            </template>

          </el-table-column>

            <el-table-column
              color="primary"
              style="opacity: 0;"
              min-width="130"
              prop="exchange"
              :label="$t('closeOperations.exchange')">
            </el-table-column>

          <el-table-column
            prop="price"
            min-width="160"
            :label="$t('openOperations.price')"
            sortable>
          </el-table-column>

          <el-table-column
            prop="buy"
            min-width="160"
            :label="$t('openOperations.boughtBy')"
            sortable>
          </el-table-column>

          <el-table-column
            prop="amount"
            min-width="150"
            :label="$t('openOperations.amount')"
            sortable>
          </el-table-column>

          <el-table-column
            prop="invested"
            min-width="120"
            :label="$t('openOperations.invested')"
            sortable>
          </el-table-column>

          <el-table-column
            prop="currentValue"
            min-width="160"
            :label="$t('openOperations.currentValue')"
            sortable>
          </el-table-column>

          <el-table-column
            prop="situation"
            min-width="150"
            :label="$t('openOperations.situation')"
            sortable>
            <template slot-scope="scope">
             <span :class="situationClass(scope.row.situation)"> {{ viewSituation(scope.row) }} </span>
            </template>
          </el-table-column>

          <el-table-column
            prop="createdAt"
            min-width="130"
            :label="$t('openOperations.date')"
            sortable>
            <template slot-scope="scope">
              {{ viewDate(scope.row.createdAt) }}
            </template>
          </el-table-column>

          <el-table-column
            prop="actions"
            min-width="150"
            :label="$t('openOperations.action')">
            <template slot-scope="scope">
              <base-button class="btn btn-success btn-sm" style="word-break: break-word" size="sm" v-if="showSellNow(scope.row)" @click="sellNow(scope.row)" block>
                {{ $t("openOperations.sellNow") }}
              </base-button>

              <base-button class="btn btn-warning btn-sm" style="word-break: break-word" size="sm" v-if="showSellAtLoss(scope.row)" @click="sellNowLoss(scope.row)" block>
                {{ $t("openOperations.sellAtLoss") }}
              </base-button>

              <router-link :to="{ name: 'Errors' }" v-if="showErrros(scope.row)">
                <base-button class="btn btn-danger btn-sm" style="word-break: break-word" size="sm" block>
                  {{ $t("orders.error") }}
                </base-button>
              </router-link>
            </template>
          </el-table-column>

        </el-table>
        <div class="text-center" v-if="operations.length === 0"> {{ $t('openOperations.none') }} </div>
      </card>
    </div>
  </div>
</template>
<script>

import { BaseNav, Badge, BaseAlert } from '@/components'
import { Table, TableColumn } from 'element-ui'
import { mapGetters } from 'vuex'
import moment from 'moment';
import balance from '../../../balance.vue'
import { listEventCompany } from '@/services/index'

export default {
  props: ["ws"],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseNav,
    Badge,
    BaseAlert,
    'company-user-balance': balance
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    ...mapGetters({
      user: 'company/user'
    }),

    operations: function () {
      return this.user.operations
    }
  },
  methods: {
    viewDate(date) {
      return moment(date).format()
    },

    viewSituation(row) {
      return `${((row.price * row.amount) - (row.buy * row.amount_buy)).toFixed(2)} (${row.situation}%)`
    },

    showSellNow(row) {
      return row.action && row.request !== true && row.countError < 3
    },

    showSellAtLoss(row) {
      return !row.action && row.currentValue >= 11 && row.request !== true && row.countError < 3
    },

    showErrros(row) {
      return row.countError >= 3
    },

    async sellNow(row) {
       if ((confirm(this.$t("openOperations.doYouSell")) && row.request !== true)) {
        row.request = true

        try {
          const body = {
            action: "SellNow",
            orderId: row.orderId,
            operationId: row.id
          }
          await listEventCompany(this.user.id, body)
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });

        } catch (e) {
          const { status, data = [] } = e.response || {}
          let message = e.message
          if (status === 422) {
            let erros = []
            for (const item in data) {
              erros.push(data[item].join("\n"))
            }
            message = erros.join("\n")
          }
          this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        }

        setTimeout(() => row.request = false, 10000);
      }
    },

    async sellNowLoss(row) {
       if ((confirm(this.$t("openOperations.doYouSell")) && row.request !== true)) {
        row.request = true

        try {
          const body = {
            action: "SellNowLoss",
            orderId: row.orderId,
            operationId: row.id
          }
          await listEventCompany(this.user.id, body)
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });

        } catch (e) {
          const { status, data = [] } = e.response || {}
          let message = e.message
          if (status === 422) {
            let erros = []
            for (const item in data) {
              erros.push(data[item].join("\n"))
            }
            message = erros.join("\n")
          }
          this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        }

        setTimeout(() => row.request = false, 10000);
      }
    },

    situationClass(risk) {
      if (risk <= 0) return 'text-warning';
      return 'text-success'
    },
  },
  mounted() {

  },
  beforeDestroy() {

  },
  created() {
    this.companyVerifyUser()
  }
};
</script>

