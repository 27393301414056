<template>
  <div class="row">

    <company-user-balance />

    <div class="col-md-12" v-if="user && user.id">
      <card>
        <template slot="header">
          <h4 class="card-title">
            <router-link :to="{ name: 'CompanyUsers' }">
              <base-button class="ml-2" size="sm" simple type="success">
                {{ $t("return") }}
              </base-button>
            </router-link>
            {{ $t('accountStatus.title', { current: 'USD' }) }}
          </h4>
        </template>

        <div class="row">

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                  {{ $t('accountStatus.valueInPurchases') }}  <br />
                  <strong> ${{ valueInPurchases }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                  {{ $t('accountStatus.deficit') }}  <br />
                  <strong :class="situationClass(-deficit)"> ${{ -deficit }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                  {{ $t('accountStatus.profit') }}  <br />
                  <strong :class="situationClass(sumProfit)"> ${{ sumProfit }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center" v-if="requestOn === false">
                  {{ $t('accountStatus.altcoinsSaved') }}  <br />
                  <strong :class="situationClass(sumSymbolsOnHold)"> ${{ sumSymbolsOnHold }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center" v-if="requestOn === false">
                  {{ $t('accountStatus.altcoinsSold') }}  <br />
                  <strong :class="situationClass(sumOfConfirmedAmountOnHold)"> ${{ sumOfConfirmedAmountOnHold }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center"  v-if="requestOn === false">
                  {{ $t('accountStatus.netProfit') }}  <br />
                  <strong :class="situationClass(profit)"> ${{ profit }} </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                  {{ $t('accountStatus.riskRate') }}  <br />
                  <strong :class="riskRateClass(riskRate())"> {{ riskRate() }}% </strong>
                </h4>
              </template>
            </card>
          </div>

          <div class="col-md-3">
            <card class="block-card">
              <template slot="header">
                <div class="card-title mt-4 spinner-grow spinner-grow-xl" v-loading="requestOn"> </div>
                <h4 class="card-title text-center text-danger" v-if="requestOn === false">
                  {{ $t('dashboard.capitalAtRisk') }}  <br />
                  <strong :class="riskRateClass(capitalAtRisk)"> ${{ capitalAtRisk }} </strong>
                </h4>
              </template>
            </card>
          </div>
        </div>

        <card>
          <template slot="header">
            <h4 class="card-title">
              {{ $t('accountStatus.note') }}
            </h4>
          </template>

          <div class="row">
            <div class="col-md-12">
              <base-alert type="success">
                {{ $t('risk1') }}
              </base-alert>
            </div>
            <div class="col-md-12">
              <base-alert type="warning">
                {{ $t('risk2') }}
              </base-alert>
            </div>
            <div class="col-md-12">
              <base-alert type="danger">
                {{ $t('risk3') }}
              </base-alert>
            </div>
            <div class="col-md-12">
              <base-alert type="danger">
                {{ $t('risk4') }}
              </base-alert>
            </div>
          </div>
        </card>

      </card>
    </div>

  </div>
</template>
<script>

import { Table, TableColumn } from 'element-ui'
import { BaseAlert, BaseCheckbox, Badge, Modal } from '@/components'
import { mapGetters } from 'vuex'
import balance from '../balance.vue'

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseAlert,
    BaseCheckbox,
    Badge,
    Modal,
    'company-user-balance': balance
  },
  data() {
    return {
      requestOn: false,
      search: ""
    };
  },

  computed: {
    ...mapGetters({
      user: 'company/user',
      valueInPurchases: "company/valueInPurchases",
      deficit: "company/deficit",
      sumProfit: "company/sumProfit",
      sumSymbolsOnHold: "company/sumSymbolsOnHold",
      sumOfConfirmedAmountOnHold: "company/sumOfConfirmedAmountOnHold",
      profit: "company/profit",
      riskRate: "company/riskRate",
      capitalAtRisk: "company/capitalAtRisk",
      onlyView: "user/onlyView",
    }),

    profit: function() {
      return parseFloat(((this.sumProfit + this.sumOfConfirmedAmountOnHold + this.sumSymbolsOnHold) - this.deficit).toFixed(2))
    },
  },

  methods: {

    situationClass(risk) {
      if (risk < 0) return 'text-warning';
      return 'text-success'
    },

    riskRateClass(risk) {
      if (risk <= 30) return 'text-success';
      if (risk <= 50) return 'text-warning';
      return 'text-danger';
    },

    showSituation(v1, v2) {
      return parseFloat((((v1 / v2) * 100) - 100).toFixed(2));
    },


  },

  async created() {
    this.companyVerifyUser()
  },

  mounted() { }
};
</script>
<style></style>
