<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <card>
            <template slot="header">
              <h4 class="card-title"> {{ $t('extract.extract') }}  </h4>
            </template>

            <div class="card-body">
              <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
              <div v-if="rows.data.length">
                <el-table class="table-striped" :data="rows.data">

                  <el-table-column
                    color="primary"
                    style="opacity: 0;"
                    min-width="50"
                    prop="indication"
                    :label="$t('extract.indicated')">
                  </el-table-column>

                  <el-table-column
                    color="primary"
                    style="opacity: 0;"
                    min-width="50"
                    prop="type"
                    :label="$t('extract.operation')">
                    <template slot-scope="scope">
                      <span :class="addClass(scope.row.type)"> {{ scope.row.type }} </span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    color="primary"
                    style="opacity: 0;"
                    min-width="50"
                    prop="value"
                    :label="$t('extract.extractValue')">
                    <template slot-scope="scope">
                      <span :class="addClass(scope.row.type)"> {{ scope.row.value }} </span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    color="primary"
                    style="opacity: 0;"
                    min-width="50"
                    prop="date"
                    :label="$t('extract.date')">
                    <template slot-scope="scope">
                      <span> {{ showDate(scope.row.date) }} </span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    color="primary"
                    style="opacity: 0;"
                    min-width="150"
                    prop="description"
                    :label="$t('extract.description')">
                  </el-table-column>

                </el-table>
                <base-pagination :total="rows.total" v-if="rows.last_page > 1" v-model="rows.current_page" @input="changePage"></base-pagination>
              </div>

              <div class="text-center" v-if="rows.data.length === 0 && requestOn === false"> {{ $t('extract.none') }} </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { Table, TableColumn } from 'element-ui'
import { BasePagination } from '@/components'
import { bankStatementsApi } from '@/services'

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination
  },
  data() {
    return {
      requestOn: false,
      rows: {
        total: 0,
        current_page: 1,
        last_page: 1,
        data: []
      },
      balance: {}
    };
  },
  computed: {

  },
  methods: {
    showDate(date) {
      return moment(date, "DD/MM/YYYY").format()
    },
    changePage(page) {
      this.list(page)
    },
    addClass(type) {
      return (type !== 'd' && type !== 'débito')  ? 'text-success' : 'text-danger'
    },
    async list(page = 1) {
      this.requestOn = true
      try {
        const bankStatement = await bankStatementsApi({ page })

       const { data, meta } = bankStatement.data

        this.rows.total = meta.total
        this.rows.current_page = meta.current_page
        this.rows.last_page = meta.last_page
        this.rows.data = data

        this.balance = { value: parseFloat(bankStatement.data.resume.balance.replaceAll("-", "").replaceAll(".", "").replaceAll(",", ".")), type: bankStatement.data.resume.type === "débito" ? 'd' : 'c'  }

      } catch (e) {
        console.info(e)
      }
      this.requestOn = false
    }
  },
  created() {
    this.list()
  },
  mounted() {

  },
  beforeDestroy() {

  }
};
</script>
