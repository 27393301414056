<template>
    <div class="col-md-12">
      <card  v-if="user && user.id">
        <template slot="header">
          <h4 class="card-title">
            <strong class="ml-2"> {{ user.name }} </strong><br /><br />

            <base-button size="sm" simple type="success" class="ml-2" :disabled="synchronize" @click="synchronizeNow()">
              {{ $t('dashboard.synchronize') }}
            </base-button>

            <strong class="tim-icons icon-pencil  ml-5 c-p" primary @click="action('CompanyEditUser')"> {{ $t("orders.massEditing") }} </strong>
            <strong class="tim-icons icon-chart-bar-32 ml-5 c-p text-success" primary @click="action('CompanyOverviewUser')"> {{ $t("sidebar.overview") }} </strong>
            <strong class="tim-icons icon-alert-circle-exc ml-5 c-p text-danger" primary @click="action('CompanyErrorsUser')"> {{ $t("sidebar.errors") }} </strong>
            <strong class="tim-icons icon-notes ml-5 c-p" @click="action('CompanyOrdersUser')"> {{ $t("orders.title") }}  </strong>
            <strong class="tim-icons icon-cart ml-5 c-p text-warning" @click="action('CompanyOperationsOpenUser')"> {{ $t("route.OperationsOpen") }}  </strong>
            <strong class="tim-icons icon-check-2 ml-5 c-p text-success" @click="action('CompanyOperationsClosedUser')"> {{ $t("route.OperationsClosed") }}  </strong>

          </h4>
          <table class="table">
            <thead>
              <tr>
                <th colspan="2"> BINANCE </th>
                <th> KUCOIN </th>
                <th> HUOBI </th>
                <th> OKEX </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> <strong> USDT: <span class="text-success"> ${{ user.usdtBinance }} </span> </strong> </td>
                <td> <strong> BUSD: <span class="text-success"> ${{ user.busdBinance }} </span> </strong> </td>
                <td> <strong> USDT: <span class="text-success"> ${{ user.usdtKucoin }}  </span> </strong> </td>
                <td> <strong> USDT: <span class="text-success"> ${{ user.usdtHuobi }}  </span> </strong> </td>
                <td> <strong> USDT: <span class="text-success"> ${{ user.usdtOkex }}    </span> </strong> </td>
              </tr>
            </tbody>
          </table>
        </template>
      </card>
    </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import { companyUpdateUser } from '@/services/index'

export default {
  props: ['ws'],
  components: {
  },
  data() {
    return {
      routeName: ''
    };
  },
  computed: {
    ...mapGetters({
      user: 'company/user',
      synchronize: 'company/synchronize',
      onlyView: "user/onlyView"
    }),
  },
  methods: {

    ...mapActions({
      setSynchronize: 'company/setSynchronize'
    }),

    action(name) {
      this.$router.push({ name })
    },

    async synchronizeNow() {

      this.setSynchronize(true)

      try {
        await companyUpdateUser(this.user.id, { name: this.user.name })
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
      } catch (e) {
       const { status, data = [] } = e.response || {}
       let message = e.message
       if (status === 422) {
         let erros = []
         for (const item in data) {
           erros.push(data[item].join("\n"))
         }
         message = erros.join("\n")
       }
       this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 15000 });
      }

      setTimeout(() => this.setSynchronize(false), 50000);
    }
  },
  async created() {

  },
  mounted() {
  }
};
</script>
<style></style>
