<template>
  <div class="row">
    <balance :ws="ws" />
    <brokerage-situation :ws="ws" />

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <card :title="$t('contactUs.important')">
            <p class="pr-5" v-html="$t('contactUs.note', { uri })"> </p>
          </card>
        </div>
        <div class="col-md-9">
          <card :title="$t('contactUs.title')">
            <div>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
                <form v-if="onlyView === false" @submit.prevent="handleSubmit(submit)">
                  <div class="card-body">
                    <div>
                      <ValidationProvider
                        :name='$t("contactUs.subject")'
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        required
                        v-model="form.subject"
                        autocomplete="off"
                        :placeholder='$t("contactUs.subject")'
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <ValidationProvider
                        :name='$t("contactUs.message")'
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                        >
                        <textarea class="form-control" rows="7"
                         required
                          v-model="form.text"
                          autocomplete="off"
                        :placeholder='$t("contactUs.message")'
                        >
                        </textarea>
                      </base-input>
                    </ValidationProvider>
                    </div>

                    <div slot="footer">
                      <base-button native-type="submit" type="primary" class="mb-3" :disabled="invalid || requestOn" size="lg" block>
                        {{ $t("contactUs.send") }}
                        <span class="spinner-grow" v-loading="requestOn"></span>
                      </base-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import { extend } from "vee-validate";
import { required} from "vee-validate/dist/rules";
import { sendContact } from '@/services'

extend("required", required);


export default {
  props: ['ws'],
  components: {
  },
  data() {
    return {
      requestOn: false,
      uri: "",
      form: {
        subject: "",
        text: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      onlyView: "user/onlyView"
    })
  },
  methods: {
    async submit() {
      this.requestOn = true;
      try {
        await sendContact(this.form)
        this.form = { subject: "", text: "" };
        this.$nextTick(() => this.$refs.form.reset());

        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
      } catch (e) {
        this.$notify({ type: 'danger', message: this.$t("requestError") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
      }
      this.requestOn = false;
    }
  },
  created() {
    this.uri = this.$router.resolve({ name: 'FrequentlyAskedQuestions' }).href
  },
  mounted() {

  },
  beforeDestroy() {

  }
};
</script>
