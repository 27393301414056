<template>
  <div class="container">
    <div class="row">
      <h1 style="display:none"> {{ $t("meetNeiO") }} </h1>
      <sidebar-share> </sidebar-share>

      <div class="col-md-7 mr-auto ml-auto mt-4">
        <back-to-login />

        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-body">
              <template slot="header">
                <!-- <img class="card-img" src="img/card-primary.png" alt="Card image"/> -->
                <h1 class="card-title">  {{ $t("register.register") }} </h1>
              </template>

              <ValidationProvider
                :name='$t("register.nickname")'
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  type="text"
                  autocomplete="off"
                  required
                  v-model="form.nickname"
                  :placeholder='$t("register.nickname")'
                  addon-left-icon="tim-icons icon-single-02"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>


              <ValidationProvider
                :name='$t("register.email")'
                rules="required|email"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  type="email"
                  autocomplete="off"
                  required
                  v-model="form.email"
                  :placeholder='$t("register.email")'
                  addon-left-icon="tim-icons icon-email-85"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                :name='$t("register.password")'
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  type="password"
                  autocomplete="off"
                  required
                  v-model="form.password"
                  :placeholder='$t("register.password")'
                  addon-left-icon="tim-icons icon-lock-circle"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>


              <base-input
                readonly="readonly"
                autocomplete="off"
                required
                v-model="form.referralCode"
                :placeholder='$t("register.referralCode")'
                addon-left-icon="tim-icons icon-single-02">
              </base-input>


              <errors :rows="getErrors()" />

              <ValidationProvider
                name='terms'
                rules="required|bool"
                v-slot="{ passed, failed, errors }"
                >
                <base-checkbox
                  v-model="form.terms"
                  type="checkbox"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  class="text-left">
                    {{ $t("terms.n1") }}
                    <a href="https://www.neio.tech/politica-privacidade-neio" class="d-text d-s" target="black_private">
                      {{ $t("terms.n2") }}
                    </a>,
                    {{ $t("terms.n3") }}
                    <a href="https://www.neio.tech/termo-de-uso-neio" class="d-text d-s" target="black_terms">
                      {{ $t("terms.n4") }}
                    </a>
                </base-checkbox>
              </ValidationProvider>

              <base-button native-type="submit" slot="footer" type="primary" :disabled="invalid || requestOn" round block size="lg">
                {{ $t("register.submit") }}
              </base-button>
            </card>
          </form>
        </ValidationObserver>

        <networks />

        <modal v-if="modalOpen" :show.sync="modalOpen">
          <template slot="header">
            <h4 class="modal-title text-success"> {{ $t("Registration completed successfully") }} </h4>
          </template>

          <div class="embed-responsive mb-5" style="font-size: 1.1em;">
            {{ $t("messageRegister") }}
          </div>

          <router-link class="text-warning" to="login">
             {{ $t("close to login") }}
          </router-link>

          <template slot="footer"> </template>
        </modal>

      </div>
    </div>
  </div>
</template>
<script>
import SidebarShare from '@/pages/Layout/SidebarSharePlugin';
import { BaseCheckbox, Modal, BaseAlert } from 'src/components';
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { createUser } from '@/services';
import Errors from '@/components/Errors/Index'
import Networks from '@/pages/Components/Networks'
import BackToLogin from '@/pages/Components/BackToLogin'

extend("email", email);
extend("required", required);
extend("min", min);
extend("bool", (value) => value === true)

export default {
  components: {
    BaseCheckbox,
    SidebarShare,
    Errors,
    Networks,
    BackToLogin,
    Modal,
    BaseAlert
  },
  data() {
    return {
      modalOpen: false,
      requestOn: false,
      form: {
        email: '',
        nickname: '',
        password: '',
        terms: false,
        referralCode: '',
      }
    };
  },
  methods: {

    reset() {
      this.form = {
        email: '',
        nickname: '',
        password: '',
        terms: false,
      }
    },

    async submit() {
      this.requestOn = true
      this.resetErrors()

      try {
        const { email, nickname, password, referralCode } = this.form
        await createUser({
          email,
          nickname,
          password,
          name: nickname,
          code: referralCode
        });

        this.reset()
        this.$nextTick(() => this.$refs.form.reset());

        this.modalOpen = true
      } catch ({ response }) {
        const { data } = response;
        const errors = Object.values(data).map(i => i[0])
        this.setErrors(errors)
      }
      this.requestOn = false
    }
  },
  created() {
    if (this.$route.query.code || localStorage.getItem('code')) {
      if (this.$route.query.code)
        localStorage.setItem('code', this.$route.query.code);

      this.form.referralCode = localStorage.getItem('code');
    }

    if (this.$route.query.sed) {
      location.replace("https://www.neio.tech/neio-hold")
      return;
    }

    if (!this.form.referralCode)
      this.form.referralCode = "NOCODE"
  },
};
</script>
