<template>
  <div class="row">
    <balance :ws="ws" />
    <brokerage-situation :ws="ws" />

    <div class="col-md-12">
      <div class="row">

        <div class="col-md-12">
          <card>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div>
                    <span v-html="$t('indicatorCode', { code: indicatorCode })"></span>
                    <base-button simple type="primary" class="mb-2 ml-4" size="sm" v-if="canIndicator" :disabled="requestOn" @click="submitIndicator()">
                      {{ $t("addIndicator") }}
                    </base-button>
                  </div>
                </div>
                <div class="col-md-6">
                  {{ $t('youRegisterIn', { date: created_at }) }}
                </div>
              </div>
            </div>
          </card>
        </div>

        <div class="col-md-5">
          <card :title="$t('referralProgram.seeLast')">
            <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
            <div v-if="requestOn === false">
              <h4 class="card-title"> {{ $t('referralProgram.totalOfReferences') }}: {{ total }} </h4>
              <h5 :key="row.id" v-for="row of rows">
                #{{row.id}} <br />
                {{ showCreatedAt(row.created_at) }}
              </h5>
            </div>
          </card>
        </div>
        <div class="col-md-7">
          <card :title="$t('referralProgram.title')">
            <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
            <div v-if="requestOn === false">
              <div>
                <p> {{ $t('referralProgram.description') }} </p>
                <p class="pt-4">
                  {{ $t('referralProgram.linkText') }}
                  <a target="_blank" :href="`https://web.neio.network/register?code=${indicationCode}`">
                    https://web.neio.network/register?code={{indicationCode}}
                  </a>
                </p>
                <p class="pt-4">
                  <a target="_blank" href="https://www.neio.tech/indicacao">
                    {{ $t('referralProgram.knowMore') }}
                  </a>
                </p>
                <p>
                  {{ $t("sub1") }} <br />
                  {{ $t("sub2") }}<br />
                  {{ $t("sub3") }}<br /><br />

                  {{ $t("sub4") }}<br />
                </p>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import moment from 'moment';
import { indicationsApi, indicationApi, userInfo, setIndicator } from '@/services'
import Card from '../../components/Cards/Card.vue';

export default {
  props: ['ws'],
  components: {
    Card
  },
  data() {
    return {
      requestOn: false,
      rows: [],
      total: 0,
      indicationCode: '',
      indicatorCode: '',
      canIndicator: false,
      created_at: ''
    };
  },
  computed: {

  },
  methods: {
    async list() {
      this.requestOn = true

      try {
        const cod = await indicationApi()
        this.indicationCode = cod.data.indication.code

        const inds = await indicationsApi()
        this.rows = inds.data.rows
        this.total = inds.data.total

        const resUser = await userInfo()
        this.indicatorCode = resUser.data.indicatorCode || this.$t('none')
        this.canIndicator = resUser.data.indicatorCode ? false : true
        this.created_at = moment(resUser.data.created_at, "DD/MM/YYYY hh:mm").format()

      } catch (e) {
        console.info(e.message)
      }
      this.requestOn = false
    },
    showCreatedAt(date) {
      return moment(date, "DD/MM/YYYY hh:mm").format()
    },

    async submitIndicator() {
      const code = prompt(this.$t('whichIndicatorCode'))
      if (confirm(this.$t("codeIndicator", { code }))) {
        this.requestOn = true

        try {
          await setIndicator({ code })
          this.$notify({ type: 'success', message: this.$t("settings.verificationSuccessful") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 10000 });

          setTimeout(async () => await this.list(), 1000);
        } catch ({ response }) {
          const message = []
          for (const key in response.data) message.push(response.data[key])
          this.$notify({ type: 'danger', message: message.join(','), verticalAlign: 'top', horizontalAlign: 'center', timeout: 15000 });
        }
        this.requestOn = false
      }
    }
  },
  async created() {
    await this.list()
  },
  mounted() {

  },
  beforeDestroy() {

  }
};
</script>
