<template>
  <div class="row">
    <balance :ws="ws" />
    <brokerage-situation :ws="ws" />

    <div class="col-md-6">
      <card :title='$t("settings.exchanges")' class="min-card">
        <div class="card-body">
          <tabs type="primary" vertical class="row">
            <tab-pane :label='$t("settings.binance")'>
              <div class="card-body">
                <div type="nav-tabs">
                  <div>


                    <ValidationObserver ref="formBinance" v-slot="{ handleSubmit, invalid }">
                      <form  v-if="onlyView === false" @submit.prevent="handleSubmit(submitExchange)">
                        <div>
                          <ValidationProvider
                            :name='$t("settings.form.api")'
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              required
                              v-model="form.api"
                              autocomplete="off"
                              :placeholder='$t("settings.form.api")'
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>

                          <ValidationProvider
                              :name='$t("settings.form.secret")'
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              required
                              v-model="form.secret"
                              autocomplete="off"
                              :placeholder='$t("settings.form.secret")'
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>

                          <div slot="footer">
                            <base-button native-type="submit" type="primary" class="mb-3" :disabled="invalid || requestOn" size="lg" block>
                              {{ form.active ? $t("settings.change") : $t("settings.register") }}
                              <span class="spinner-grow" v-loading="requestOn"></span>
                            </base-button>

                            <base-button size="sm"
                              simple
                              type="danger"
                              v-if="form.active"
                              :disabled="requestOn"
                              @click="deleteApi('binance')"
                              >
                                {{ $t("delete API", { name: 'binance' }) }}
                            </base-button>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                  <div>
                    <base-alert type="warning">
                      <h5> {{ $t('infoTitleBinance') }} </h5>
                      <h5>
                        {{ $t('infoText1Binance') }} <br />
                        {{ $t('infoText2Binance') }} <br />
                        {{ $t('infoText3Binance') }} <br />
                        {{ $t('infoText4Binance') }} <br />
                      </h5>
                        <base-button class="btn-sm" @click="modalOpenBinance=true"> {{ $t("infoBtBinance") }}  </base-button>
                    </base-alert>
                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane :label='$t("settings.huobi")' v-if="formHuobi.apiIsActiveHuobi">
              <div class="card-body">
                <div type="nav-tabs">
                  <div>

                    <ValidationObserver ref="formHuobi" v-slot="{ handleSubmit, invalid }">
                      <form  v-if="onlyView === false" @submit.prevent="handleSubmit(submitExchangeHuobi)">
                        <div>
                          <ValidationProvider
                            :name='$t("settings.formHuobi.api")'
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              required
                              v-model="formHuobi.apiHuobi"
                              autocomplete="off"
                              :placeholder='$t("settings.formHuobi.api")'
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>

                          <ValidationProvider
                              :name='$t("settings.formHuobi.secret")'
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              required
                              v-model="formHuobi.secretHuobi"
                              autocomplete="off"
                              :placeholder='$t("settings.formHuobi.secret")'
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>

                          <div slot="footer">
                            <base-button native-type="submit" type="primary" class="mb-3" :disabled="invalid || requestOn" size="lg" block>
                              {{ formHuobi.apiIsActiveHuobi ? $t("settings.change") : $t("settings.register") }}
                              <span class="spinner-grow" v-loading="requestOn"></span>
                            </base-button>

                            <base-button size="sm"
                              simple
                              type="danger"
                              class="mb-2"
                              v-if="formHuobi.apiIsActiveHuobi"
                              :disabled="requestOn"
                              @click="deleteApi('huobi')"
                              >
                                {{ $t("delete API", { name: 'huobi' }) }}
                            </base-button>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane label='kucoin'>
              <div class="card-body">
                <div type="nav-tabs">
                  <div>

                    <ValidationObserver ref="formKucoin" v-slot="{ handleSubmit, invalid }">
                      <form  v-if="onlyView === false" @submit.prevent="handleSubmit(submitExchangeKucoin)">
                        <div>
                          <ValidationProvider
                            :name='$t("settings.formKucoin.api")'
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              required
                              v-model="formKucoin.apiKucoin"
                              autocomplete="off"
                              :placeholder='$t("settings.formKucoin.api")'
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>

                          <ValidationProvider
                              :name='$t("settings.formKucoin.secret")'
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              required
                              v-model="formKucoin.secretKucoin"
                              autocomplete="off"
                              :placeholder='$t("settings.formKucoin.secret")'
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>

                          <ValidationProvider
                              :name='$t("settings.formKucoin.passphrase")'
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              required
                              v-model="formKucoin.passphraseKucoin"
                              autocomplete="off"
                              :placeholder='$t("settings.formKucoin.passphrase")'
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>

                          <div slot="footer">
                            <base-button native-type="submit" type="primary" class="mb-3" :disabled="invalid || requestOn" size="lg" block>
                              {{ formKucoin.apiIsActiveKucoin ? $t("settings.change") : $t("settings.register") }}
                              <span class="spinner-grow" v-loading="requestOn"></span>
                            </base-button>

                              <base-button size="sm"
                                simple
                                type="danger"
                                v-if="formKucoin.apiIsActiveKucoin"
                                :disabled="requestOn"
                                @click="deleteApi('kucoin')"
                                >
                                  {{ $t("delete API", { name: 'kucoin' }) }}
                              </base-button>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane label='okex'>
              <div class="card-body">
                <div type="nav-tabs">
                  <div>

                    <ValidationObserver ref="formOkex" v-slot="{ handleSubmit, invalid }">
                      <form  v-if="onlyView === false" @submit.prevent="handleSubmit(submitExchangeOkex)">
                        <div>
                          <ValidationProvider
                            :name='$t("settings.formOkex.api")'
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              required
                              v-model="formOkex.apiOkex"
                              autocomplete="off"
                              :placeholder='$t("settings.formOkex.api")'
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>

                          <ValidationProvider
                              :name='$t("settings.formOkex.secret")'
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              required
                              v-model="formOkex.secretOkex"
                              autocomplete="off"
                              :placeholder='$t("settings.formOkex.secret")'
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>

                          <ValidationProvider
                              :name='$t("settings.formOkex.passphrase")'
                              rules="required"
                              v-slot="{ passed, failed, errors }"
                            >
                            <base-input
                              required
                              v-model="formOkex.passphraseOkex"
                              autocomplete="off"
                              :placeholder='$t("settings.formOkex.passphrase")'
                              :error="errors[0]"
                              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                            </base-input>
                          </ValidationProvider>

                          <div slot="footer">
                            <base-button native-type="submit" type="primary" class="mb-3" :disabled="invalid || requestOn" size="lg" block>
                              {{ formOkex.apiIsActiveOkex ? $t("settings.change") : $t("settings.register") }}
                              <span class="spinner-grow" v-loading="requestOn"></span>
                            </base-button>

                            <base-button size="sm"
                              simple
                              type="danger"
                              v-if="formOkex.apiIsActiveOkex"
                              :disabled="requestOn"
                              @click="deleteApi('okex')"
                              >
                                {{ $t("delete API", { name: 'okex' }) }}
                            </base-button>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </div>
      </card>
    </div>

    <div class="col-md-6">
      <card :title='$t("settings.titleWallet")'  class="min-card">
        <div class="card-body">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
            <form  v-if="onlyView === false" @submit.prevent="handleSubmit(submitUser)">
              <div>
                <ValidationProvider
                  :name='$t("settings.formWallet.wallet")'
                  :rules="{ required: false, regex: /^0x[a-fA-F0-9]{40}$/ }"
                  v-slot="{ passed, failed, errors }"
                  >
                  <base-input
                    required
                    v-model="formWallet.wallet"
                    autocomplete="off"
                    :label='$t("settings.formWallet.wallet")'
                    :placeholder='$t("settings.formWallet.wallet")'
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>

                <div slot="footer">
                  <base-button native-type="submit" type="primary" class="mb-3"  v-if="onlyView === false" :disabled="invalid || requestOn" size="lg" block>
                    {{ formWallet.active ? $t("settings.change") : $t("settings.register") }}
                    <span class="spinner-grow" v-loading="requestOn"></span>
                  </base-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </card>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <card :title='$t("settings.maximumValueOperation")' class="min-card">
            <div class="card-body">
              <strong class="text-center"> USD ${{ formWallet.max_buy }} </strong>
              <slider
                v-model="formWallet.max_buy"
                type="success"
                class="mt-4"
                :options="{ step: 1 }"
                :range='{ "min": 20, "max": 10000 }'
                >
              </slider>
              <base-button native-type="submit" type="primary" class="mb-3 mt-4"  v-if="onlyView === false" :disabled="requestOn" size="lg" block @click="submitUserMax()">
                {{ $t("settings.change") }}
                <span class="spinner-grow" v-loading="requestOn"></span>
              </base-button>
            </div>
          </card>
        </div>
        <div class="col-md-6">
          <card :title='$t("settings.2fa")' class="min-card">
            <div class="card-body">
              <div class="row" v-if="twoAuth.two_active === false">
                <div class="col-md-6">
                  <img :src="twoAuth.qrcode_image" class="img-thumbnail" style="max-width: 180px;" /> <br />
                  <strong> {{ twoAuth.two_key }} </strong>
                </div>
                <div class="col-md-6">
                  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
                    <form  v-if="onlyView === false" @submit.prevent="handleSubmit(submitTwoAuth)">
                      <div>
                        <ValidationProvider
                          :name='$t("settings.code")'
                            rules="required|min:6|max:6"
                            v-slot="{ passed, failed, errors }"
                          >
                          <base-input
                            required
                            v-model="code"
                            autocomplete="off"
                            :placeholder='$t("settings.code")'
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                          </base-input>
                        </ValidationProvider>

                        <div slot="footer">
                          <base-button native-type="submit" type="primary" class="mb-3" :disabled="invalid || requestOn" size="lg" block>
                            {{ $t("settings.register") }}
                            <span class="spinner-grow" v-loading="requestOn"></span>
                          </base-button>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
              <div class="text-center text-success" v-if="requestOn === false && twoAuth.two_active">
                 {{ $t("settings.2faActive") }}
              </div>
            </div>
          </card>
        </div>
        <div class="col-md-4">
          <card class="min-card" :title="$t('settings.notifications')">
            <div class="card-body">
              <base-button type="primary" size="sm" class="mb-2">
               <a href="https://t.me/neio_official_bot" target="_blank" rel="noopener" class="text-muted">
                <b class="text-white"> {{ $t("settings.receiveYourAccessKey") }} </b>
               </a>
              </base-button>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
                <form v-if="onlyView === false" @submit.prevent="handleSubmit(submitUpdateTelegram)">
                  <div>
                    <ValidationProvider
                      :name='$t("settings.chatUuid")'
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                      <base-input
                        required
                        v-model="formTelegram.chatUuid"
                        autocomplete="off"
                        :placeholder='$t("settings.chatUuid")'
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>

                    <div slot="footer">
                      <base-button native-type="submit" type="primary" class="mb-2" v-if="onlyView === false" :disabled="invalid || requestOn" size="lg">
                        {{ form.chatIdIsActive ?  $t("settings.change") : $t("settings.register") }}
                        <span class="spinner-grow" v-loading="requestOn"></span>
                      </base-button>

                      <base-button simple type="success" class="mb-2 ml-4" size="lg" v-if="form.chatIdIsActive && onlyView === false" :disabled="requestOn" @click="verifyTelegramSubmit()">
                        {{ $t("settings.check") }}
                        <span class="spinner-grow" v-loading="requestOn"></span>
                      </base-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </card>
        </div>
        <div class="col-md-8">

          <card class="min-card" v-if="blocked === false" :title="$t('settings.deactivateAccount')">
            <div class="card-body">
              <base-button simple type="danger" class="mb-2" v-if="!doYouReallyRequest && onlyView === false" @click="disable()"> {{ $t('settings.deactivateAccount') }} </base-button>
            </div>
          </card>

          <card class="min-card" v-if="blocked === true" :title="$t('settings.activateAccount')">
            <div class="card-body">
              <base-button simple type="success" class="m-4 mb-0" v-if="!doYouReallyRequest && onlyView === false" @click="activateAccount()"> {{ $t('settings.activateAccount') }} </base-button>

              <base-alert type="warning" v-if="messageDebit" class="m-4 mt-0 mb-10">
                  <span v-html="messageDebit"></span>
              </base-alert>

              <base-button simple type="danger" class="m-4" v-if="!doYouReallyRequest && onlyView === false" @click="resetAll()"> {{ $t('resetAllBt') }} </base-button>
            </div>
          </card>


        </div>
      </div>
    </div>

    <modal size="xl" v-if="modalOpenBinance" :show.sync="modalOpenBinance">
      <div class="embed-responsive" style="min-height:520px">
      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/NU98yIVMiNI" allowfullscreen></iframe>
      </div>
      <base-button class="ml-2 float-right" size="sm" simple type="primary" @click="modalOpenBinance = false"> X </base-button>
      <template slot="footer"> </template>
    </modal>

    <modal v-if="modalApiFirst" :show.sync='modalApiFirst'>
      <div class="embed-responsive">
        <BaseAlert type="success" v-html="$t('s01')">  </BaseAlert>
      </div>

      <router-link :to="{ name: 'Orders' }">
        <base-button class="mb-2" size="sm" block simple type="success"> {{ $t("sl01") }} </base-button>
      </router-link>
      <base-button class="mb-2" size="sm" block simple type="warning" @click="modalApiFirst = false"> {{ $t("sl02") }} </base-button>

      <template slot="footer"></template>
    </modal>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { mapGetters, mapActions } from 'vuex'
import { required, min, max, regex } from "vee-validate/dist/rules";
import { TabPane, Tabs, Collapse, CollapseItem, Slider, BaseAlert, Modal } from '@/components';
import { userInfo, setApi, setApiFtx, setApiHuobi, setApiKucoin, setApiOkex, updateUser, twoFactors, verifyTwoKey, updateTelegram, verifyTelegram, debitUnlockEvent, resetAll, deleteApi } from "@/services"

extend("required", required);
extend("min", min);
extend("max", max);
extend("regex", regex);

export default {
  props: ['ws'],
  components: {
    Modal,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
    Slider,
    BaseAlert
  },

  data() {
    return {
      modalOpenBinance: false,
      modalApiFirst: false,
      requestOn: false,
      doYouReallyRequest: false,
      messageDebit: "",
      code: "",
      twoAuth: { },
      formTelegram: {
        chatUuid: "",
      },
      form: {
        active: false,
        chatIdIsActive: false,
        exchange: "binance",
        api: "",
        secret: "",
      },
      formFtx: {
        activeFtx: false
      },
      formHuobi: {
        apiIsActiveHuobi: false
      },
      formKucoin: {
        apiIsActiveKucoin: false
      },
      formOkex: {
        apiIsActiveOkex: false
      },
      formWallet: {
        max_buy: 20,
        active: false,
        wallet: "",
        memo: "",
      }

    };
  },
  methods: {
    ...mapActions({
      setToken: 'user/setToken',
      setStatus: "exchange/setStatus",
    }),

    disable() {
      if (this.ws.connected) {
        if (confirm(this.$t("doYouReally"))) {
          this.doYouReallyRequest = true
          this.ws.emit('disable', {});
          setTimeout(() => { location.reload() }, 1000);
        }
      }
    },

    async activateAccount() {
      if (confirm(this.$t("doYouReally"))) {
        this.doYouReallyRequest = true
        try {
          await debitUnlockEvent()
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
          this.messageDebit = ""
        } catch (e) {
          if (e.response) {
            const { debit, wallet, memo, name } = e.response.data;
            this.messageDebit = this.$t("debitAlert", { name, memo, wallet, value: debit })
          } else {
            console.log('Error', e.message);
          }
        }
        this.doYouReallyRequest = false
      }
    },

    async resetAll() {
      if (confirm(this.$t("resetInfo"))) {
        this.doYouReallyRequest = true
        try {
          const response = await resetAll()
          console.info(response)
          this.setToken(response.data.token)

          window.location.reload();
        } catch ({ response }) {
          const { data } = response;
          const errors = Object.values(data).map(i => i[0])
          const message = errors.shift()
          this.$notify({ type: 'warning', message, verticalAlign: 'top', horizontalAlign: 'center', timeout: 10000 });
        }
        this.doYouReallyRequest = false
      }
    },

    async loadTwoFactors() {
      try {
        const res = await twoFactors()
        this.twoAuth = res.data;
      } catch (e) {
        console.error(e)
      }
    },

    async load() {
      try {
        const resUser = await userInfo()
        this.formFtx.activeFtx = resUser.data.apiIsActiveFtx || false
        this.formHuobi.apiIsActiveHuobi = resUser.data.apiIsActiveHuobi
        this.formKucoin.apiIsActiveKucoin = resUser.data.apiIsActiveKucoin || false
        this.formOkex.apiIsActiveOkex = resUser.data.apiIsActiveOkex || false
        this.form.active = resUser.data.apiIsActive || false
        this.form.chatIdIsActive = resUser.data.chatIdIsActive || false

        this.formWallet.max_buy = resUser.data.max_buy || 20
        this.formWallet.wallet = resUser.data.wallet || ""
        this.formWallet.memo = resUser.data.memo || ""
        this.formWallet.active = resUser.data.memo ? true : false
      } catch (e) {
        console.error(e)
      }
    },

    async deleteApi(exchange) {
      if (!confirm(this.$t("really want to do this"))) {
        return
      }

      this.requestOn = true
      try {
        await deleteApi({ exchange })
        await this.load()

        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error, verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
      }
      this.requestOn = false
    },

    async verifyTelegramSubmit() {
      this.requestOn = true
      try {
        await verifyTelegram()
        this.$notify({ type: 'success', message: this.$t("settings.verificationSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error, verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
      }
      this.requestOn = false
    },

    async submitUpdateTelegram() {
      this.requestOn = true
      try {
        const { chatUuid } = this.formTelegram;

        await updateTelegram({ chatUuid })

        this.formTelegram.chatUuid = ""
        this.$nextTick(() => this.$refs.form.reset());
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });

        window.location.reload();
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error, verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
      }
      this.requestOn = false
    },

    async submitExchange() {
      this.requestOn = true
      try {
        const { api, secret } = this.form
        await setApi({ api, secret })
        this.form.api = ""
        this.form.secret = ""

        if (this.form.active === false && this.orders.length > 0) {
          this.modalApiFirst = true
        } else {
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
        }

        this.form.active = true

        this.$nextTick(() => this.$refs.formBinance.reset());
        this.setStatus({ key: 'binance', value: { error: '' } })
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error, verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
      }
      this.requestOn = false
    },


    async submitExchangeFtx() {
      this.requestOn = true
      try {
        const { apiFtx, secretFtx, accountFtx } = this.formFtx
        await setApiFtx({ apiFtx, secretFtx, accountFtx })
        this.formFtx.apiFtx = ""
        this.formFtx.secretFtx = ""
        this.formFtx.accountFtx = ""
        this.$nextTick(() => this.$refs.formFtx.reset());
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error, verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
      }
      this.requestOn = false
    },


    async submitExchangeHuobi() {
      this.requestOn = true
      try {
        const { apiHuobi, secretHuobi } = this.formHuobi
        await setApiHuobi({ apiHuobi, secretHuobi })
        this.formHuobi.apiHuobi = ""
        this.formHuobi.secretHuobi = ""
        this.formHuobi.apiIsActiveHuobi = true
        this.$nextTick(() => this.$refs.formHuobi.reset());
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });

        this.setStatus({ key: 'huobi', value: { error: '' } })
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error, verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
      }
      this.requestOn = false
    },


    async submitExchangeKucoin() {
      this.requestOn = true
      try {
        const { apiKucoin, secretKucoin, passphraseKucoin } = this.formKucoin
        await setApiKucoin({ apiKucoin, secretKucoin, passphraseKucoin })
        this.formKucoin.apiKucoin = ""
        this.formKucoin.secretKucoin = ""
        this.formKucoin.passphraseKucoin = ""

        if (this.formKucoin.apiIsActiveKucoin === false && this.orders.length > 0) {
          this.modalApiFirst = true
        } else {
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
        }

        this.formKucoin.apiIsActiveKucoin = true
        this.$nextTick(() => this.$refs.formKucoin.reset());
        this.setStatus({ key: 'kucoin', value: { error: '' } })
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error, verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
      }
      this.requestOn = false
    },


    async submitExchangeOkex() {
      this.requestOn = true
      try {
        const { apiOkex, secretOkex, passphraseOkex } = this.formOkex
        await setApiOkex({ apiOkex, secretOkex, passphraseOkex })
        this.formOkex.apiOkex = ""
        this.formOkex.secretOkex = ""
        this.formOkex.passphraseOkex = ""

        if (this.formOkex.apiIsActiveOkex === false && this.orders.length > 0) {
          this.modalApiFirst = true
        } else {
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
        }

        this.formOkex.apiIsActiveOkex = true
        this.$nextTick(() => this.$refs.formOkex.reset());
        this.setStatus({ key: 'okex', value: { error: '' } })
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error, verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
      }
      this.requestOn = false
    },

    async submitUserMax() {
      this.requestOn = true
      try {
        await updateUser({ max_buy: this.formWallet.max_buy })
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error || this.$t("requestError"), verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
      }
      this.requestOn = false
    },

    async submitUser() {
      this.requestOn = true
      try {
        await updateUser(this.formWallet)
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 15000 });
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error || this.$t("requestError"), verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
      }
      this.requestOn = false
    },


    async submitTwoAuth() {
      this.requestOn = true
      try {
        const res = await verifyTwoKey({ secret: this.code })

        if (res.data.valid === false) {
          this.$notify({ type: 'danger', message: this.$t("requestError"), verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
        } else {
          await this.loadTwoFactors()
        }
      } catch (e) {
        this.$notify({ type: 'danger', message: e.response.data.error || this.$t("requestError"), verticalAlign: 'bottom', horizontalAlign: 'center', timeout: 20000 });
        console.error(e)
      }
      this.requestOn = false
    }
  },
  created() {
    this.load()
    this.loadTwoFactors()
  },
    computed: {
    ...mapGetters({
      blocked: 'user/blocked',
      onlyView: "user/onlyView",
      orders: 'user/orders',
    }),
  }
};
</script>
<style scoped>
 .min-card {
    min-height: 307px;
 }
</style>
