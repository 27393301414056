<template>
  <div class="row">
    <balance :ws="ws" />
    <brokerage-situation :ws="ws" />

  <div class="col-md-6" v-if="blocked === false">
    <card class="card-chart">
      <template slot="header">
        <h4 class="card-title"> {{ titlePosition }}  </h4>
      </template>
      <div class="card-body">

        <div class="spinner-grow spinner-grow-xl" v-loading="requestOn || !changeBroker || requestTime"> </div>

        <pie-chart
          v-if="piePosition.chartData.labels.length && requestOn === false && changeBroker && !requestTime"
          :chart-data="piePosition.chartData"
          :extra-options="piePosition.extraOptions"
          :height="200"
        >
        </pie-chart>
        <div class="text-center" v-if="!piePosition.chartData.labels.length && requestOn === false && changeBroker && !requestTime"> {{ $t('dashboard.none') }} </div>
      </div>
    </card>
  </div>

    <div class="col-md-12">
      <card :title='$t("wallet.title", { t: exchange })'>
        <div class="card-body">
          <div v-if="blocked === true && requestOn === false">
            <base-alert type="danger">
              {{ $t('orders.deactivatedAccount') }}
            </base-alert>
          </div>
          <div v-if="blocked === false">
            <el-table class="table-striped" :default-sort = "{ prop: 'amountOnOrderInNeioView', order: 'descending' }" :data="walletListAll">

                <el-table-column
                  prop="symbol"
                  min-width="160"
                  :label="$t('wallet.symbol')"
                  sortable>
                </el-table-column>

                <el-table-column
                  prop="amountOnOrderInNeio"
                  min-width="270"
                  :label="$t('wallet.amountOnOrderInNeio')"
                  sortable>
                </el-table-column>

                <el-table-column
                  prop="amountOnOrderInNeioView"
                  min-width="200"
                  :label="$t('wallet.valueOnOrderInNeio')"
                  sortable>
                  <template slot-scope="scope">
                    ${{ scope.row.amountOnOrderInNeioView  }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop="amountAtExchange"
                  min-width="270"
                  :label="$t('wallet.amountAtExchange')"
                  sortable>
                </el-table-column>


                <el-table-column
                  prop="amountAtExchangeView"
                  min-width="200"
                  :label="$t('wallet.valueAtExchange')"
                  sortable>
                  <template slot-scope="scope">
                    ${{ scope.row.amountAtExchangeView  }}
                  </template>
                </el-table-column>
            </el-table>
          </div>
        </div>
      </card>
    </div>


  </div>
</template>
<script>
import { extend } from "vee-validate";
import { mapGetters, mapActions } from 'vuex'
import { required, min, max } from "vee-validate/dist/rules";
import { TabPane, Tabs, Collapse, CollapseItem, Slider, BaseAlert } from '@/components';
import { twoFactors, resetAll, symbolsPrice } from "@/services"
import {Table, TableColumn} from 'element-ui'
import { piePosition } from "../../pages/Dashboard/charts"
import PieChart from 'src/components/Charts/PieChart';

extend("required", required);
extend("min", min);
extend("max", max);

export default {
  props: ['ws'],
  components: {
    TabPane,
    Tabs,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Collapse,
    CollapseItem,
    Slider,
    BaseAlert,
    PieChart
  },

  data() {
    return {
      symbols: [],
      requestOn: false,
      requestTime: true,
      switchOn: false,
      twoAuth: { },
      titlePosition: "",
      piePosition: piePosition()
    };
  },

  watch: {
    changeBroker(n, o) {
      setTimeout(() => this.requestTimeLoad(), 3000);
    }
  },

  methods: {
    ...mapActions({
      setToken: 'user/setToken'
    }),

    requestTimeLoad() {
      this.requestTime = true

      const list = this.walletListAll.filter((row, index) => index < 5)
      const sumValue = this.walletListAll.map(row =>row.amountOnOrderInNeioView).reduce((next, currentValue) => next + currentValue, 0)

      this.titlePosition = this.$t("titlePosition", { q: list.length })

      // getPercent

      const pieP = piePosition()

      list.forEach(item => {
        pieP.chartData.labels.push(`${item.symbol} (${this.getPercent(item.amountOnOrderInNeioView, sumValue)}%)`)
        pieP.chartData.datasets[0].data.push(item.amountOnOrderInNeioView)
      })

      this.piePosition = pieP;
      this.requestTime = false
    },

    async resetAll() {
      if (confirm(this.$t("resetInfo"))) {
        this.doYouReallyRequest = true
        try {
          const response = await resetAll()
          console.info(response)
          this.setToken(response.data.token)

          window.location.reload();
        } catch ({ response }) {
          const { data } = response;
          const errors = Object.values(data).map(i => i[0])
          const message = errors.shift()
          this.$notify({ type: 'warning', message, verticalAlign: 'top', horizontalAlign: 'center', timeout: 10000 });
        }
        this.doYouReallyRequest = false
      }
    },


    getPercent(qtd, total) {
      return (qtd * 100 / total).toFixed(2)
    },

    async loadTwoFactors() {
      try {
        const res = await twoFactors()
        this.twoAuth = res.data;
      } catch (e) {
        console.error(e)
      }
    },

    async listSymbol() {
      const { data } = await symbolsPrice()
      this.symbols = data
    }
  },
  async created() {
    this.requestOn = true
    await this.loadTwoFactors()
    await this.listSymbol()
    this.requestOn = false


    setTimeout(() => this.requestTimeLoad(), 5000);
  },



  computed: {
    ...mapGetters({
      orders: 'user/orders',
      blocked: 'user/blocked',
      changeBroker: "user/changeBroker",
      exchange: "user/exchange",
      walletList: "user/walletList",
      onlyView: "user/onlyView"
    }),

    walletListAll: function() {
      return this.walletList.map((wl) => {
        const symbol = this.symbols.filter(s => s.currency.toUpperCase() !== 'HUSD').find(s => wl.symbol.toUpperCase() === s.symbol.toUpperCase())

        if (symbol !== undefined) {
          wl.amountAtExchangeView = parseFloat((wl.amountAtExchange * symbol.price).toFixed(2))
          wl.amountOnOrderInNeioView = parseFloat((wl.amountOnOrderInNeio * symbol.price).toFixed(2))
        }

        return wl
      }).sort((a, b) => b.amountOnOrderInNeioView - a.amountOnOrderInNeioView)
    },
  }
};
</script>
<style scoped>
 .min-card {
    min-height: 307px;
 }
</style>
