<template>
  <card class="min-height">
    <template slot="header">
      <h4 class="card-title"> {{ $t('your wallet') }} </h4>
    </template>
    <div class="card-body">
      <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="!evm" v-loading="true"> </div>
      <div v-if="evm">
        <div class="row">
          <div class="col-md-4">
            <select class="form-control mb-2 max-width" aria-label="Small" v-model="form.coin" @input="changeCoin">
              <option value=""> {{ $t('Select coin') }} </option>
              <option :value="item" v-for="item in coins" :key="item.label"> {{ item.label }} </option>
            </select>

            <select class="form-control mb-2 max-width" aria-label="Small"  v-model="form.network" v-if="form.coin">
              <option value=""> {{ $t('Select network') }} </option>
              <option :value="item.value" v-for="item in networks.filter(n => form.coin.network.includes(n.value))" :key="item.label"> {{ item.label }} </option>
            </select>
          </div>
          <div class="col-md-8">
            <div v-if="form.coin && form.network">
              <strong class="mb-1"> {{ $t('address') }}:</strong>
              <h5 class="pl-2 mt-1">
                {{ evm }}
                <i class="tim-icons icon-single-copy-04 copyOne pl-2" @click="copyText(evm)"></i>
                <span class="bg-white">
                  <qrcode-vue :value="evm" :size="110" level="H" render-as="canvas" class="p-1 bg-white"></qrcode-vue>
                </span>
              </h5>
             </div>
          </div>

          <div class="col-md-12">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
              <form @submit.prevent="handleSubmit(submitTx)">

                <base-alert type="danger">
                  <small class="text-white strong"> {{ $t("noteWallet") }} </small>
                </base-alert>

                <ValidationProvider
                    :name='$t("pay.txHash")'
                    :rules="{ required: true, regex: /^0x([A-Fa-f0-9]{64})$/ }"
                    v-slot="{ passed, failed, errors }"
                  >
                  <base-input
                    required
                    size="sm"
                    bg
                    placeholder="TXHASH"
                    autocomplete="off"
                    v-model="form.txHash"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
                </ValidationProvider>

                <errors :rows="getErrors()" />

                <base-button native-type="submit" type="primary" class="mb-2" :disabled="invalid || requestOnTx" block>
                  {{ $t('verify transaction') }}
                </base-button>

              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { BaseAlert } from 'src/components'
import Errors from '@/components/Errors/Index'
import { sendPayConfirmingTransaction, bankStatementsApi } from '@/services'
import { mapActions } from 'vuex'

export default {
  props: ['ws', 'evm'],
  components: {
    QrcodeVue,
    BaseAlert,
    Errors
  },
  data() {
    return {
      requestOnTx: false,

      coins: [
        { label: 'USDT TetherUS', value: 'USDT TetherUS', network: ['bsc', 'matic', 'kcc'] },
        //{ label: 'BUSD', value: 'BUSD', network: ['bsc', 'matic'] },
        { label: 'USDC', value: 'USDC', network: ['bsc', 'matic'] },
        { label: 'DAI', value: 'DAI', network: ['bsc', 'matic'] },
      ],
      networks: [
        { label: 'BNB Smart Chain (BEP20)', value: 'bsc' },
        { label: 'MATIC Polygon', value: 'matic' },
        { label: 'KuCoin Community Chain(KCC)', value: 'kcc' },
      ],
      form: {
        coin: '',
        network: '',
        txHash: ''
      }
    };
  },
  computed: {
  },
  methods: {
    ...mapActions({
      setSituation: "userState/setSituation"
    }),

    copyText(value) {
      const text = value.replace(/\s/g, '')
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        this.$notify({ type: 'success', message: this.$t("copiedText") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 5000 });
      } catch (err) {}
      document.body.removeChild(textArea);
    },

    changeCoin() {
      this.form.network = ''
    },

    async submitTx() {
      if (confirm(this.$t("doYouReally"))) {
        this.requestOnTx = true

        try {
          this.resetErrors()
          await sendPayConfirmingTransaction(this.form)

          const bankStatement = await bankStatementsApi()

          const usd = parseFloat(bankStatement.data.resume.balance.replaceAll("-", "").replaceAll(".", "").replaceAll(",", "."))
          const type = bankStatement.data.resume.type === "débito" ? 'd' : 'c'

          this.setSituation({ usd, type })

          this.$notify({ type: 'success', message: this.$t("pay.computed") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 15000 })
        } catch ({ response }) {
          const { data } = response;
          const errors = Object.values(data).shift()

          this.setErrors( typeof errors === 'string' ? [ errors ] : errors )
        }

        this.form.txHash = ''
        this.$nextTick(() => this.$refs.form.reset())

        this.requestOnTx = false
      }
    },
  }
};
</script>
<style scoped>
  .copyOne {
    cursor: pointer;
  }

  .max-width {
    max-width: 200px;
  }

  .min-height {
    min-height: 380px;
  }

  .strong {
    font-weight: 900!important;
  }
</style>
