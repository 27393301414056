<template>
  <div class="row">

    <company-user-balance />

    <div class="col-md-6">
      <card>
        <template slot="header">
          <h4 class="card-title">
            <router-link :to="{ name: 'CompanyUsers' }">
              <base-button class="ml-2" size="sm" simple type="success">
                {{ $t("return") }}
              </base-button>
            </router-link>

            {{ $t("orders.massEditing") }}
          </h4>
        </template>
        <div class="card-body">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
            <form @submit.prevent="handleSubmit(submitNewUser)">

              <ValidationProvider
                  :name='$t("companyUser.name")'
                  :rules="{}"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.name"
                  autocomplete="off"
                  :label='$t("companyUser.name")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
              <!-- binance -->
              <ValidationProvider
                  :name='$t("companyUser.apiBinance")'
                  :rules="{ min: 7 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.apiBinance"
                  autocomplete="off"
                  :label='$t("companyUser.apiBinance")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("companyUser.secretBinance")'
                  :rules="{ min: 7 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.secretBinance"
                  autocomplete="off"
                  :label='$t("companyUser.secretBinance")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <!-- huobi -->
              <ValidationProvider
                  :name='$t("companyUser.apiHuobi")'
                  :rules="{ min: 7 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.apiHuobi"
                  autocomplete="off"
                  :label='$t("companyUser.apiHuobi")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("companyUser.secretHuobi")'
                  :rules="{ min: 7 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.secretHuobi"
                  autocomplete="off"
                  :label='$t("companyUser.secretHuobi")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <!-- kucoin -->
              <ValidationProvider
                  :name='$t("companyUser.apiKucoin")'
                  :rules="{ min: 7 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.apiKucoin"
                  autocomplete="off"
                  :label='$t("companyUser.apiKucoin")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("companyUser.secretKucoin")'
                  :rules="{ min: 7 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.secretKucoin"
                  autocomplete="off"
                  :label='$t("companyUser.secretKucoin")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("companyUser.passphraseKucoin")'
                  :rules="{ min: 3 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.passphraseKucoin"
                  autocomplete="off"
                  :label='$t("companyUser.passphraseKucoin")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <!-- Okex -->
              <ValidationProvider
                  :name='$t("companyUser.apiOkex")'
                  :rules="{ min: 7 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.apiOkex"
                  autocomplete="off"
                  :label='$t("companyUser.apiOkex")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("companyUser.secretOkex")'
                  :rules="{ min: 7 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.secretOkex"
                  autocomplete="off"
                  :label='$t("companyUser.secretOkex")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                  :name='$t("companyUser.passphraseOkex")'
                  :rules="{ min: 3 }"
                  v-slot="{ passed, failed, errors }"
                >
                <base-input
                  v-model="form.passphraseOkex"
                  autocomplete="off"
                  :label='$t("companyUser.passphraseOkex")'
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <base-button native-type="submit" type="primary" class="mb-3" v-if="onlyView === false" :disabled="invalid || !someMarkedField || requestOn" size="lg" block>
                {{ $t('update') }}
              </base-button>
            </form>
          </ValidationObserver>
        </div>
      </card>
    </div>

    <div class="col-md-6">
      <strong class="text-success">
        {{ $t('orders.editTitle') }}
      </strong>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import { required, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { companyUpdateUser } from '@/services/index'
import balance from './balance.vue'

extend("min", min);
extend("required", required);

export default {
  props: ['ws'],
  components: {
    'company-user-balance': balance
  },
  data() {
    return {
      requestOn: false,
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      user: 'company/user',
      onlyView: "user/onlyView",
    }),

    someMarkedField: function() {
      let form = []
      for (const item in this.form) if (this.form[item]) form.push(this.form[item])
      return form.length > 0
    },
  },
  methods: {
    async submitNewUser() {
      this.requestOn = true

      let body = {}
      for (const item in this.form) {
        if (this.form[item])
          body[item] = this.form[item]
      }

      try {
        await companyUpdateUser(this.user.id, { ...body, password: 'neio' })
        this.form = {}
        this.$nextTick(() => this.$refs.form.reset());
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        setTimeout(() => this.$router.push({ name: "CompanyUsers" }), 3700);
      } catch (e) {
       const { status, data = [] } = e.response || {}
       let message = e.message
       if (status === 422 || status === 400) {
         let erros = []
         for (const item in data) {
           erros.push(data[item].join("\n"))
         }
         message = erros.join("\n")
       }

       this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 15000 });
      }

      this.requestOn = false
    }
  },
  async created() {
    this.companyVerifyUser()
  },
};
</script>
<style></style>
