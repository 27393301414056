<template>
  <div v-if="rows.length">
    <base-alert type="warning" dismissible v-for="e in rows" :key="e">
        {{ e }}
    </base-alert>
  </div>
</template>
<script>
import { BaseAlert } from '@/components'

export default {
  name: 'errors',
  components: {
    BaseAlert
  },
  props:{
    rows: {
      type: Array
    }
  },
  data() {
    return {
    };
  }
};
</script>
<style></style>
