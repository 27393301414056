<template>
  <div class="row">
    <balance :ws="ws" />
    <brokerage-situation :ws="ws" />

    <div class="col-md-12">

      <div class="row">
        <div class="col-md-5">
          <account-status :ws="ws" v-if="requestOn === false" />
        </div>

        <div class="col-md-7">
          <wallet :ws="ws" :evm="evm" v-if="requestOn === false" />
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import Wallet from './wallet.vue'
import AccountStatus from './account-status.vue'
import { bankStatementsApi, walletApi, sendPayConfirmingTransaction, userData } from '@/services'
import BaseButton from '../../components/BaseButton.vue';
import { TabPane, Tabs, Collapse, CollapseItem, Slider, BaseAlert, Modal } from '@/components';
import { Loading } from 'element-ui';
import Errors from '@/components/Errors/Index'
import { extend } from "vee-validate";
import { regex, required } from "vee-validate/dist/rules";
import { mapActions } from 'vuex'

extend("required", required);
extend("regex", regex);


export default {
  props: ['ws'],
  components: {
    BaseAlert,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
    Slider,
    Modal,
    QrcodeVue,
    BaseButton,
    Loading,
    Errors,
    Wallet,
    AccountStatus
  },
  data() {
    return {
      requestOn: false,
      requestOnTx: false,
      requestWalletOn: false,
      wallet: {},
      evm: "",
      balance: {},
      form: {
        network: '',
        txHash: ''
      }
    };
  },
  computed: {

  },
  methods: {
    ...mapActions({
      setSituation: "userState/setSituation",
      setProfile: "userState/setProfile",
    }),

    async submitTx() {
      if (confirm(this.$t("doYouReally"))) {
        this.requestOnTx = true

        try {
          this.resetErrors()
          await sendPayConfirmingTransaction(this.form)

          this.$notify({ type: 'success', message: this.$t("pay.computed") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 15000 })

          await this.list()
        } catch ({ response }) {
          const { data } = response;
          const errors = Object.values(data).shift()

          this.setErrors( typeof errors === 'string' ? [ errors ] : errors )
        }

        this.form = { network: '', txHash: '' }
        this.$nextTick(() => this.$refs.form.reset())

        this.requestOnTx = false
      }
    },

    copyText(value) {
      const text = value.replace(/\s/g, '')
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        this.$notify({ type: 'success', message: this.$t("copiedText") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 5000 });
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textArea);
    },

    async list() {
      this.requestOn = true
      try {
        let walletData = await walletApi()
        if (walletData.data.wallet.wallet_payment === null) {
          walletData = await walletApi()
        }

        const bankStatement = await bankStatementsApi()

        this.evm = walletData.data.evm || ""

        const usd = parseFloat(bankStatement.data.resume.balance.replaceAll("-", "").replaceAll(".", "").replaceAll(",", "."))
        const type = bankStatement.data.resume.type === "débito" ? 'd' : 'c'

        this.balance = { value: usd, type: type }

        this.setSituation({ usd, type })

        const data = await userData()
        this.setProfile(data)
      } catch (e) {
        console.info(e)
      }

      this.requestOn = false
    }
  },

  created() {
    this.list()

  },
};
</script>

<style>
.copyOne {
  cursor: pointer;
  font-weight: 900;
}



</style>
