<template>
  <div class="row">

    <company-user-balance />

    <div class="col-md-12" v-if="user && user.id">
      <card>
        <router-link :to="{ name: 'CompanyNewOrdersUser' }">
          <base-button class="ml-2" size="sm" simple type="success">
            {{ $t("orders.newOrder") }}
          </base-button>
        </router-link>

        <router-link :to="{ name: 'CompanyEditOrdersUser' }" v-if="massEditing.length">
          <base-button class="ml-2" size="sm" simple type="warning">
            {{ $t("orders.massEditing") }} ({{ massEditing.length }})
          </base-button>
        </router-link>

      </card>
    </div>

    <div class="col-md-12" v-if="user && user.id">
      <card>
        <template slot="header">
          <h4 class="card-title">
            <router-link :to="{ name: 'CompanyUsers' }">
              <base-button class="ml-2" size="sm" simple type="success">
                {{ $t("return") }}
              </base-button>
            </router-link>

            {{ $t('orders.title') }} ({{ user.orders.length }})
            <span v-if="search"> <badge type="warning"> {{ $t("filter") }}: {{ search }} </badge> <base-button size="sm" type="danger" @click="() => search='' "> X </base-button> </span>
          </h4>
        </template>
          <div v-if="user.orders.length && requestOn === false">
            <el-table
              class="table-striped"
              height="61vh"
              ref="multipleOrders"
              :default-sort="{ prop: 'status.value', order: 'ascending' }"
              :data="user.orders.filter(data => !search || data.symbolWithExchange.toLowerCase().includes(search.toLowerCase()))">

              <el-table-column type="expand">
                <template slot-scope="scope">
                  <div class="row">
                    <div class="col-5">
                      <card class="text-uppercase">
                        <template slot="header">
                          <h4 class="card-title">
                            {{ $t('resume') }}
                          </h4>
                        </template>
                        <div class="card-body max-operation">
                          <div class="row mb-2 b-primary">
                            <div class="col-6"> {{ $t('orders.wallet') }}: </div>
                            <div class="col-6"> {{ scope.row.wallet }} {{ scope.row.group }} </div>
                          </div>
                          <div class="row mb-2 b-primary">
                            <div class="col-6"> {{ $t('orders.inOrder') }}: </div>
                            <div class="col-6"> {{ scope.row.inWallet }} {{ scope.row.group }} </div>
                          </div>
                          <div class="row mb-2 b-primary">
                            <div class="col-6"> {{ $t('orders.inDifference') }}: </div>
                            <div class="col-6"> {{ scope.row.inDifference }} {{ scope.row.group }} </div>
                          </div>
                          <div class="row mb-2 b-primary">
                            <div class="col-6"> {{ $t('orders.averagePrice') }}: </div>
                            <div class="col-6"> {{ isNaN(scope.row.averagePrice) ? $t("unavailable") : scope.row.averagePrice }} {{ isNaN(scope.row.averagePrice) ? '' : scope.row.currency }} </div>
                          </div>
                          <div class="row mb-2 b-primary">
                            <div class="col-6"> {{ $t("closeOperations.profit") }}: </div>
                            <div class="col-6"> {{ scope.row.gainUsd }} {{ scope.row.currency }} </div>
                          </div>
                          <div class="row mb-2 b-primary">
                            <div class="col-6"> {{ $t('orders.inSavedAltcoins') }}: </div>
                            <div class="col-6"> {{ scope.row.symbolOnHoldAverageValueNow }} {{ scope.row.currency }} </div>
                          </div>
                          <div class="row mb-2 b-primary">
                            <div class="col-6"> {{ $t('orders.deficit') }}: </div>
                            <div class="col-6"> {{ scope.row.deficit }}  {{ scope.row.currency }} </div>
                          </div>
                          <div class="row mb-2 b-primary">
                            <div class="col-6"> {{ $t('orders.result') }}: </div>
                            <div class="col-6"> {{ scope.row.netIncome }} {{ scope.row.currency }} </div>
                          </div>
                        </div>
                      </card>
                    </div>
                    <div class="col-7">
                      <card class="text-uppercase">
                        <template slot="header">
                          <h4 class="card-title">
                            {{ $t('openOperations.title') }}
                            <base-button class="ml-2 mb-2 mt-1" size="sm" simple  type="warning"  v-if="scope.row.countError >= 3 && onlyView === false" :disabled="scope.row.request"  @click="clearErrors(scope.row)">
                              {{ $t('orders.clearErrors') }}
                            </base-button>
                          </h4>
                        </template>
                        <div class="card-body max-operation">
                          <div class="row">
                            <base-alert type="danger" v-if="scope.row.countError >= 3"> {{ scope.row.messageError }} </base-alert>
                          </div>
                          <div class="row mb-2  b-primary" :key="operation.id" v-for="operation of operationsByOrderId(scope.row.id)">
                            <div class="col-3"> {{ $t('orders.amount') }}: {{ operation.amount }} {{ scope.row.group }}  </div>
                            <div class="col-2"> {{ $t('orders.price') }}: {{ operation.buy }} {{ scope.row.currency }} </div>
                            <div class="col-4">  <span :class="situationClass(operation.situation)"> {{ $t("openOperations.situation")}}: {{ operation.viewSituation }} </span> </div>
                            <div class="col-3">
                              <base-button class="ml-4" size="sm" simple type="danger" v-if="(scope.row.inDifference < 0 || operation.amount === 0) && onlyView === false" @click="deleteOperation(scope.row, operation)" :disabled="scope.row.request">
                                  {{ $t('delete') }}
                              </base-button>

                              <base-button simple type="success" size="sm" v-if="showSellNow(operation) && scope.row.inDifference >= 0 && operation.amount > 0 && onlyView === false" @click="sellNow(operation, scope.row)" block :disabled="scope.row.request">
                                {{ $t("openOperations.sellNow") }}
                              </base-button>
                            </div>

                          </div>
                        </div>
                      </card>
                    </div>
                  </div>

                </template>
              </el-table-column>

              <el-table-column
                prop="selected"
                :label="$t('orders.selected')"
                >
                <template slot="header">
                  <base-checkbox class="mb-3" v-if="onlyView === false" v-model="switchOn" @input="verifySelected"></base-checkbox>
                </template>

                <template slot-scope="scope">
                  <base-checkbox class="mb-3" v-if="onlyView === false" v-model="scope.row.selected"></base-checkbox>
                </template>
              </el-table-column>

              <el-table-column
                color="primary"
                min-width="180"
                prop="symbol"
                :label="$t('orders.par')">
                <template slot="header">
                  <base-input v-model="search"
                    type="text"
                    :placeholder="$t('orders.par')"/>
                </template>

                <template slot-scope="scope">
                  {{ (scope.row.exchange || exchange).toUpperCase() }}-{{ scope.row.symbol }} <br /> <strong class="tim-icons icon-pencil" style="cursor:pointer" v-if="onlyView === false" @click="editOne(scope.row)"> {{ $t("orders.massEditing") }} </strong>
                </template>
              </el-table-column>


              <el-table-column
                color="primary"
                min-width="160"
                prop="price"
                sortable
                :label="$t('orders.price')">
                <template slot-scope="scope">
                  {{ scope.row.price }} <br /> <strong class="tim-icons icon-chart-bar-32" style="cursor:pointer" @click="showOrderView(scope.row)"> tradingview </strong>
                </template>
              </el-table-column>

              <el-table-column
                color="primary"
                min-width="170"
                prop="perfilRender.value"
                sortable
                :label="$t('orders.profile')">
                <template slot-scope="scope">
                  {{ $t(`orders.${scope.row.perfilRender.label}`) }} <br /> {{ $t('orders.profit') }}: {{ scope.row.profit }}%
                </template>
              </el-table-column>

              <el-table-column
                color="primary"
                min-width="150"
                prop="value"
                sortable
                :label="$t('orders.value')">
              </el-table-column>

              <el-table-column
                color="primary"
                min-width="120"
                prop="opens"
                sortable
                :label="$t('orders.operations')">
              </el-table-column>

              <el-table-column
                color="primary"
                min-width="100"
                prop="max"
                sortable
                :label="$t('orders.max')">
              </el-table-column>

              <el-table-column
                color="primary"
                min-width="120"
                prop="entry"
                sortable
                :label="$t('orders.entry')">
                <template slot-scope="scope">
                  {{ scope.row.entry === 0 ? "" : `${scope.row.entry}%` }}
                </template>
              </el-table-column>

              <el-table-column
                color="primary"
                min-width="100"
                prop="holdOn"
                sortable
                :label="$t('orders.hold')">
                <template slot-scope="scope">
                  <span :class="scope.row.hold.class"> {{ scope.row.hold.label }} </span>
                </template>
              </el-table-column>

              <el-table-column
                color="primary"
                min-width="150"
                prop="status.value"
                sortable
                :label="$t('orders.status')">
                <template slot-scope="scope">
                  <span :class="scope.row.status.class" v-html="$t(`orders.${scope.row.status.label}`)"> </span>
                </template>
              </el-table-column>

              <el-table-column
                color="primary"
                min-width="210"
                prop="action"
                sortable
                :label="$t('orders.actions')">
                <template slot-scope="scope">

                  <base-button class="ml-2 mb-2 mt-1" size="sm" simple
                    :type="`${scope.row.buy_lock ? 'success' : 'warning'}`"
                    :disabled="scope.row.request"
                    v-if="onlyView === false"
                    @click="orderLockOrUnlock(scope.row)">
                    {{ scope.row.buy_lock ? $t('orders.activatePurchase') : $t('orders.disablePurchase') }}
                  </base-button>

                  <base-button class="ml-2 mb-2 mt-1" size="sm" simple
                    type="info"
                    :disabled="scope.row.request"
                    v-if="canManual(scope.row)"
                    @click="BuyNow(scope.row)">
                    {{ $t('buyNow') }}
                  </base-button>

                </template>
              </el-table-column>


            </el-table>
          </div>
        <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
        <div class="text-center" v-if="user.orders.length === 0 && requestOn === false"> {{ $t('none') }} </div>
      </card>
    </div>


    <modal size="xl" v-if="modalOpen" :show.sync="modalOpen">
      <div class="embed-responsive" style="min-height:370px">
      <iframe class="embed-responsive-item" :src="uriView" allowfullscreen></iframe>
      </div>

      <base-button class="ml-2 float-right" size="sm" simple type="primary" @click="modalOpen = false"> {{ $t("close") }} </base-button>
      <template slot="footer">
      </template>
    </modal>

  </div>
</template>
<script>

import { Table, TableColumn } from 'element-ui'
import { BaseAlert, BaseCheckbox, Badge, Modal } from '@/components'
import { updateOrderCompany, listEventCompany } from '@/services/index'
import { mapGetters } from 'vuex'
import balance from '../balance.vue'

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseAlert,
    BaseCheckbox,
    Badge,
    Modal,
    'company-user-balance': balance
  },
  data() {
    return {
      requestOn: false,
      modalOpen: false,
      switchOn: false,
      uriView: '',
      search: ""
    };
  },
  computed: {
    ...mapGetters({
      user: 'company/user',
      onlyView: "user/onlyView"
    }),

    massEditing: function() {
      return this.user.orders.filter(o => o.selected === true)
    }

  },
  methods: {
    verifySelected(value) {
      this.user.orders.forEach(o => o.selected = value)
    },

    async clearErrors (row) {
      if (confirm(this.$t("doYouReally"))) {
        row.request = true
        try {
          const body = {
            action: "OrderCleanErrors",
            orderId: row.id
          }
          await listEventCompany(this.user.id, body)
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        } catch (e) {
          const { status, data = [] } = e.response || {}
          let message = e.message
          if (status === 422) {
            let erros = []
            for (const item in data) {
              erros.push(data[item].join("\n"))
            }
            message = erros.join("\n")
          }
          this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        }

        setTimeout(() => row.request = false, 10000);
      }
    },

    async deleteOperation (row, operation) {
      if (confirm(this.$t("doYouReally"))) {
        row.request = true
        try {
          const body = {
            action: "DeleteOperation",
            orderId: row.id,
            operationId: operation.id
          }
          await listEventCompany(this.user.id, body)
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });

        } catch (e) {
          const { status, data = [] } = e.response || {}
          let message = e.message
          if (status === 422) {
            let erros = []
            for (const item in data) {
              erros.push(data[item].join("\n"))
            }
            message = erros.join("\n")
          }
          this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        }
        setTimeout(() => row.request = false, 10000);
      }
    },

    async sellNow (row, operation) {
      if ((confirm(this.$t("openOperations.doYouSell")) && row.request !== true)) {
        row.request = true
        operation.request = true

        try {
          const body = {
            action: "SellNow",
            orderId: row.id,
            operationId: operation.id
          }
          await listEventCompany(this.user.id, body)
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });

        } catch (e) {
          const { status, data = [] } = e.response || {}
          let message = e.message
          if (status === 422 || status === 400) {
            let erros = []
            for (const item in data) {
              erros.push(data[item].join("\n"))
            }
            message = erros.join("\n")
          }
          this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        }


        setTimeout(() => {
          row.request = false
          order.request = false
        }, 10000);
      }
    },

    async BuyNow (row) {
      if ((confirm(this.$t("doYouReally")) && row.request !== true)) {
        row.request = true
        try {
          const body = {
            action: "BuyNow",
            orderId: row.id,
          }
          await listEventCompany(this.user.id, body)
          this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });

        } catch (e) {
          const { status, data = [] } = e.response || {}
          let message = e.message
          if (status === 422) {
            let erros = []
            for (const item in data) {
              erros.push(data[item].join("\n"))
            }
            message = erros.join("\n")
          }
          this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
        }


        setTimeout(() => {
          row.request = false
        }, 70000);
      }
    },

    canManual (row) {
      if (row.exchange.toUpperCase() === 'BINANCE' && this.user.binanceActive) return true
      if (row.exchange.toUpperCase() === 'KUCOIN' && this.user.kucoinActive) return true
      if (row.exchange.toUpperCase() === 'HUOBI' && this.user.huobiActive) return true
      if (row.exchange.toUpperCase() === 'OKEX' && this.user.okexActive) return true
      return false
    },

    showSellNow(row) {
      return row.action && row.request !== true && row.countError < 3
    },

    operationsByOrderId: function (orderId) {
      return this.user.operations.filter(o => o.orderId === orderId).sort((a, b) => b.id - a.id)
    },

    showOrderView(row) {
      this.uriView = `https://s.tradingview.com/widgetembed/?frameElementId=tradingview_aa3f1&symbol=${row.exchange}%3A${row.symbol.replace('/', '').replace('-', '')}&interval=D&saveimage=1&details=1&hide_side_toolbar=0&toolbarbg=f4f7f9&studies=%5B%5D&theme=dark&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=br&utm_source=localhost&utm_medium=widget&utm_campaign=chart&utm_term=${row.exchange}%3A${row.symbol.replace('/', '').replace('-', '')}`
      this.modalOpen = true
    },

    editOne(row) {
      this.user.orders.forEach(o => o.selected = false)
      row.selected = true
      this.$router.push({ name: 'CompanyEditOrdersUser' })
    },

    situationClass(risk) {
      if (risk <= 0) return 'text-warning';
      return 'text-success'
    },

    async orderLockOrUnlock (row) {
      const orderId = row.id
      const action = !row.buy_lock
      if (confirm(this.$t("doYouReally"))) {
        row.request = true
        setTimeout(() => row.request = false, 15000);
        await updateOrderCompany(this.user.id, orderId, { buy_lock: action })
      }
    }

  },
  async created() {
    this.companyVerifyUser()

  },
  mounted() {
  }
};
</script>
<style></style>
